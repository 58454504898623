import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  Application,
  Assets,
  Sprite,
  Graphics,
  Text,
  Container,
} from "pixi.js";
import storage from "../utils/storage";
import { getDownloadURL, ref, listAll } from "firebase/storage";

const SCALE_FACTOR = 0.4;
const DPI = 96;
const MM_TO_PIXEL = DPI / 25.4;

const HandwritingCanvasPixi = (props) => {
  const containerRef = useRef(null);
  const pixiApp = useRef(null);
  const additionalHeightTopAndBottom = 50;
  const additionalWidthLeft = 150;
  const [totalHeight, setTotalHeight] = useState(0);
  const [contentScale, setContentScale] = useState(1);

  // Find the rightmost bounding box
  let rightmostBoundingBox = -Infinity;
  props.jiix.words
    .filter((word) => "bounding-box" in word)
    .forEach((word) => {
      const rightmostPoint =
        word["bounding-box"].x + word["bounding-box"].width;
      if (rightmostPoint > rightmostBoundingBox) {
        rightmostBoundingBox = rightmostPoint;
      }
    });

  let spaceBetweenLastWordAndLanguageCorrectionMarks = 100;
  let startingPosLanguageCorrectionMarks =
    additionalWidthLeft +
    spaceBetweenLastWordAndLanguageCorrectionMarks +
    rightmostBoundingBox * MM_TO_PIXEL;

  let additionalWidthRight = 300;

  // Calculate the difference between the rightmost bounding box and the starting position of language correction marks
  const differenceRight =
    startingPosLanguageCorrectionMarks - rightmostBoundingBox * MM_TO_PIXEL;

  // Subtract the difference from additionalWidthRight
  additionalWidthRight -= differenceRight;

  // Ensure additionalWidthRight is not less than 0
  additionalWidthRight = Math.max(additionalWidthRight, 0);

  // Initialisierung der Pixi.js Application
  useEffect(() => {
    if (pixiApp.current) return;

    const initPixi = async () => {
      // Create PixiJS application
      const app = new Application();

      // Initialize with white background
      await app.init({
        background: "#ffffff",
        resizeTo: containerRef.current,
        antialias: true,
      });

      // This works for scrolling with touch on mobile Safari (all other approaches failed !!!)
      app.canvas.style.touchAction = "pan-y";
      containerRef.current.appendChild(app.canvas);
      pixiApp.current = app;

      try {
        await addImage();
        addWordBoxes();
        addAnnotations(); // Neue Zeile
      } catch (error) {
        console.error("Error initializing canvas:", error);
      } finally {
        props.setLoadingJIIX(false);
      }
    };

    initPixi();

    // Cleanup
    return () => {
      if (pixiApp.current) {
        pixiApp.current.destroy(true);
        pixiApp.current = null;
      }
    };
  }, []);

  const addImage = async () => {
    let cumulativeHeight = additionalHeightTopAndBottom;

    const loadAndAddImage = async (url, index, totalImages) => {
      try {
        console.log(
          `Loading image ${index + 1}/${totalImages} from URL: ${url}`
        );

        // Load texture
        const texture = await Assets.load(url);

        // Create sprite
        const image = new Sprite(texture);

        // Position and scale the image
        image.position.set(
          additionalWidthLeft * SCALE_FACTOR,
          cumulativeHeight * SCALE_FACTOR
        );
        image.scale.set(SCALE_FACTOR);

        // Add to stage
        pixiApp.current.stage.addChild(image);

        // Update cumulativeHeight
        cumulativeHeight += texture.height;

        // Beim letzten Bild
        if (index === totalImages - 1) {
          const finalHeight = cumulativeHeight + additionalHeightTopAndBottom;
          setTotalHeight(finalHeight);
          pixiApp.current.renderer.resize(
            pixiApp.current.screen.width,
            finalHeight * SCALE_FACTOR
          );
        }

        console.log(`Image ${index + 1} added successfully`);

        // Handle canvas dimensions for first image
        // if (index === 0) {
        //   if (props.jiix.words.length === 0) {
        //     const canvasWidth = containerRef.current.offsetWidth;
        //     const zoomFactor = (canvasWidth / texture.width) * 2;
        //     const height =
        //       props.jiix["bounding-box"].height * MM_TO_PIXEL +
        //       additionalHeightTopAndBottom * 2;

        //     pixiApp.current.renderer.resize(texture.width, height);

        //     if (!props.isMobile) {
        //       props.onScaleChange?.(zoomFactor);
        //     }
        //   }
        // }
      } catch (error) {
        console.error(`Error loading image ${index + 1}:`, error);
      }
    };

    // Get folder references
    const originalFolderRef = ref(
      storage,
      `users/${props.userId}/exams/${props.examId}/images_original`
    );
    const regularFolderRef = ref(
      storage,
      `users/${props.userId}/exams/${props.examId}/images`
    );

    try {
      // Check which folder to use
      const originalRes = await listAll(originalFolderRef);
      const taskFolderRef =
        originalRes.items.length > 0 ? originalFolderRef : regularFolderRef;

      console.log(`Using folder: ${taskFolderRef.fullPath}`);

      const res = await listAll(taskFolderRef);

      // Filter and sort image files
      const imageFiles = res.items
        .filter(
          (item) =>
            item.name.startsWith(`${props.submissionId}_page`) &&
            item.name.endsWith(".jpg")
        )
        .sort((a, b) => {
          const aMatch = a.name.match(/_page(\d+)\.jpg$/);
          const bMatch = b.name.match(/_page(\d+)\.jpg$/);
          return (
            (aMatch ? parseInt(aMatch[1], 10) : 0) -
            (bMatch ? parseInt(bMatch[1], 10) : 0)
          );
        });

      console.log(`Found ${imageFiles.length} image files`);

      if (imageFiles.length === 0) {
        console.error("No image files found");
        return;
      }

      // Load first image
      const firstImageUrl = await getDownloadURL(imageFiles[0]);
      await loadAndAddImage(firstImageUrl, 0, imageFiles.length);

      // Load remaining images
      for (let i = 1; i < imageFiles.length; i++) {
        const url = await getDownloadURL(imageFiles[i]);
        await loadAndAddImage(url, i, imageFiles.length);
      }
    } catch (error) {
      console.error("Error in addImage:", error);
    }
  };

  const addWordBoxes = () => {
    if (!props.jiix?.words) return;

    props.jiix.words.forEach((word, index) => {
      if (!word["bounding-box"]) return;

      const box = word["bounding-box"];
      const wordRect = new Graphics();

      // Initial rote Rechtecke
      wordRect
        .beginFill(0xff0000, 0.3) // Rot mit 30% Opacity
        .drawRect(
          additionalWidthLeft * SCALE_FACTOR +
            box.x * MM_TO_PIXEL * SCALE_FACTOR,
          box.y * MM_TO_PIXEL * SCALE_FACTOR +
            additionalHeightTopAndBottom * SCALE_FACTOR,
          box.width * MM_TO_PIXEL * SCALE_FACTOR,
          box.height * MM_TO_PIXEL * SCALE_FACTOR
        )
        .endFill();

      // Diese zwei Zeilen sind wichtig für Interaktivität!
      wordRect.eventMode = "static";
      wordRect.cursor = "pointer";

      // Hover: Blaue Rechtecke mit höherer Opacity
      wordRect.on("pointerover", () => {
        wordRect.clear();
        wordRect
          .beginFill(0x0000ff, 0.5) // Blau mit 50% Opacity
          .drawRect(
            additionalWidthLeft * SCALE_FACTOR +
              box.x * MM_TO_PIXEL * SCALE_FACTOR,
            box.y * MM_TO_PIXEL * SCALE_FACTOR +
              additionalHeightTopAndBottom * SCALE_FACTOR,
            box.width * MM_TO_PIXEL * SCALE_FACTOR,
            box.height * MM_TO_PIXEL * SCALE_FACTOR
          )
          .endFill();
      });

      // Zurück zu rot beim Hover-Out
      wordRect.on("pointerout", () => {
        wordRect.clear();
        wordRect
          .beginFill(0xff0000, 0.3) // Zurück zu rot mit 30% Opacity
          .drawRect(
            additionalWidthLeft * SCALE_FACTOR +
              box.x * MM_TO_PIXEL * SCALE_FACTOR,
            box.y * MM_TO_PIXEL * SCALE_FACTOR +
              additionalHeightTopAndBottom * SCALE_FACTOR,
            box.width * MM_TO_PIXEL * SCALE_FACTOR,
            box.height * MM_TO_PIXEL * SCALE_FACTOR
          )
          .endFill();
      });

      wordRect.on("pointerdown", () => {
        if (props.onWordClick) {
          props.onWordClick(wordRect.wordData);
        }
      });

      pixiApp.current.stage.addChild(wordRect);
    });
  };

  const addAnnotations = () => {
    if (!props.annotations) return;

    // Maps für die Korrekturzeichen
    const mistakeTypesPerLineMap = new Map();
    const lineIdToAnnotationIdsMap = new Map();

    props.annotations.forEach((annotation) => {
      // Finde Start-Wort
      let startWordNum = -1;
      let endWordNum = -1;
      let wordLengthSum = 0;

      // Finde Start-Wort
      for (let i = 0; i < props.jiix.words.length; i++) {
        const word = props.jiix.words[i];
        wordLengthSum += word.label.length;

        if (
          wordLengthSum >= annotation.startPos &&
          word.label !== " " &&
          word.label !== "\n"
        ) {
          startWordNum = i;
          break;
        }
      }

      // Finde End-Wort
      wordLengthSum = 0;
      for (let i = 0; i < props.jiix.words.length; i++) {
        const word = props.jiix.words[i];
        wordLengthSum += word.label.length;

        if (
          wordLengthSum >= annotation.endPos &&
          word.label !== " " &&
          word.label !== "\n"
        ) {
          endWordNum = i;
          break;
        }
      }

      const startWord = props.jiix.words[startWordNum];
      const endWord = props.jiix.words[endWordNum];

      // // Berechne die Positionen mit angepasster Skalierung
      // const startX =
      //   (additionalWidthLeft + startWord["bounding-box"].x * MM_TO_PIXEL) *
      //   SCALE_FACTOR;
      // const startY = startWord["bounding-box"].y * MM_TO_PIXEL * SCALE_FACTOR;
      // const endX =
      //   (additionalWidthLeft + endWord["bounding-box"].x * MM_TO_PIXEL) *
      //   SCALE_FACTOR;
      // const endY = endWord["bounding-box"].y * MM_TO_PIXEL * SCALE_FACTOR;

      // // Nur die wichtigen Elemente
      // // 1. Unterstreichungslinie
      // const line = new Graphics();
      // line
      //   .lineStyle(2 * SCALE_FACTOR, 0xff0000, 0.5) // Dünnere, halbtransparente rote Linie
      //   .moveTo(
      //     startX,
      //     startY + startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR
      //   ) // Linie unter dem Wort
      //   .lineTo(
      //     endX + endWord["bounding-box"].width * MM_TO_PIXEL * SCALE_FACTOR,
      //     endY + endWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR
      //   );
      // pixiApp.current.stage.addChild(line);

      // Berechne die Positionen
      const startX =
        (additionalWidthLeft + startWord["bounding-box"].x * MM_TO_PIXEL) *
        SCALE_FACTOR;
      const startY =
        (startWord["bounding-box"].y * MM_TO_PIXEL +
          additionalHeightTopAndBottom) *
        SCALE_FACTOR;

      const width =
        (endWord["bounding-box"].x +
          endWord["bounding-box"].width -
          startWord["bounding-box"].x) *
        MM_TO_PIXEL *
        SCALE_FACTOR;
      const height = 6 * SCALE_FACTOR; // Höhe des Unterstreichungs-Rechtecks

      // Unterstreichungs-Rechteck
      const underline = new Graphics();
      underline
        .beginFill(0x0000ff, 0.5) // Blau mit 50% Transparenz
        .drawRect(
          startX,
          startY +
            startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR,
          width,
          height
        )
        .endFill();

      // Hover-Effekt: Rot beim Hover
      underline.eventMode = "static";
      underline.cursor = "pointer";

      underline.on("pointerover", () => {
        underline.clear();
        underline
          .beginFill(0xff0000, 0.5) // Rot beim Hover
          .drawRect(
            startX,
            startY +
              startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR,
            width,
            height
          )
          .endFill();
      });

      underline.on("pointerout", () => {
        underline.clear();
        underline
          .beginFill(0x0000ff, 0.5) // Zurück zu Blau
          .drawRect(
            startX,
            startY +
              startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR,
            width,
            height
          )
          .endFill();
      });

      pixiApp.current.stage.addChild(underline);

      // 2. Text
      const annotationText = new Text(
        annotation.correct || annotation.type || "✗",
        {
          fontFamily: "Arial",
          fontSize: 24 * SCALE_FACTOR,
          fontWeight: "bold",
          fill: 0xff0000,
          align: "center",
          stroke: 0xffffff, // Weißer Umriss
          strokeThickness: 4, // Dicke des Umrisses
        }
      );

      // Text über der Linie positionieren
      annotationText.position.set(
        startX,
        startY - 20 * SCALE_FACTOR // Text über dem Wort
      );
      pixiApp.current.stage.addChild(annotationText);

      const currentTypes = annotation.type.split("|").map((type) => {
        const mark = props.correctionMarks.find(
          (mark) => mark.abbreviation === type
        );
        return mark ? mark.customAbbreviation || mark.abbreviation : type;
      });

      currentTypes.forEach((currentType) => {
        const trimmedType = currentType.trim();
        const matchingMark = props.correctionMarks.find(
          (mark) => mark.customAbbreviation === trimmedType
        );
        const color = "#0088FF"; // Default color

        // Finde die zugehörige Zeile
        const lineId = props.jiix.words[startWordNum].lineId;
        let updatedValue = mistakeTypesPerLineMap.get(lineId) || "";
        updatedValue += `${trimmedType}[${color}],`;
        mistakeTypesPerLineMap.set(lineId, updatedValue);

        // Speichere Annotation ID
        if (!lineIdToAnnotationIdsMap.has(lineId)) {
          lineIdToAnnotationIdsMap.set(lineId, [annotation.id]);
        } else {
          lineIdToAnnotationIdsMap.get(lineId).push(annotation.id);
        }
      });
    });

    // Zeichne die Korrekturzeichen
    let maxXPosition = -Infinity;
    mistakeTypesPerLineMap.forEach((letters, lineId) => {
      const lineBbox = props.jiix.lines.find((line) => line.lineId === lineId)[
        "bounding-box"
      ];
      const middlePointY =
        (lineBbox.newY ?? lineBbox.y) +
        ((lineBbox.newY ?? lineBbox.y + lineBbox.height) -
          (lineBbox.newY ?? lineBbox.y)) *
          0.25;

      let xPosition = 0;
      let yPosition = 0;

      // Split und filtere die Einträge
      const letterEntries = letters.split(/[,]/).filter((entry) => entry);

      letterEntries.forEach((entry, index) => {
        const colorMatch = entry.match(/\[(#.*?)\]/);
        const color = colorMatch ? colorMatch[1] : "#FF0000";
        const label = entry.replace(/\[.*?\]/, "").trim();

        // Erstelle Text in PixiJS
        const correctionText = new Text(label, {
          fontFamily: "Arial",
          fontSize: 35 * SCALE_FACTOR,
          fill: color,
          align: "left",
        });

        correctionText.position.set(
          (startingPosLanguageCorrectionMarks + xPosition) * SCALE_FACTOR,
          (middlePointY * MM_TO_PIXEL +
            additionalHeightTopAndBottom +
            yPosition) *
            SCALE_FACTOR
        );

        // Interaktivität
        correctionText.eventMode = "static";
        correctionText.cursor = "pointer";

        pixiApp.current.stage.addChild(correctionText);

        // Update Position
        xPosition += correctionText.width / SCALE_FACTOR + 5 / SCALE_FACTOR;
        if (xPosition > maxXPosition) {
          maxXPosition = xPosition;
        }

        if (label.length > 20) {
          xPosition = 0;
          yPosition += 40 / SCALE_FACTOR;
        }
      });
    });

    const requiredWidth =
      (startingPosLanguageCorrectionMarks +
        maxXPosition +
        additionalWidthRight) *
      SCALE_FACTOR;

    // Calculate the zoom factor based on the maxXPosition
    const canvasWidth = containerRef.current.offsetWidth;

    const zoomFactor = canvasWidth / requiredWidth; // Doppelter Zoom

    // pixiApp.current.renderer.resize(requiredWidth, totalHeight * SCALE_FACTOR);
    scaleContent(zoomFactor);
  };

  // Add new scroll handling
  const scrollToPreventBounce = useCallback((element) => {
    const { scrollTop, offsetHeight, scrollHeight } = element;

    // If at top, bump down 1px
    if (scrollTop <= 0) {
      element.scrollTo(0, 1);
      return;
    }

    // If at bottom, bump up 1px
    if (scrollTop + offsetHeight >= scrollHeight) {
      element.scrollTo(0, scrollHeight - offsetHeight - 1);
    }
  }, []);

  useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const handleTouchStart = () => scrollToPreventBounce(element);
    element.addEventListener("touchstart", handleTouchStart);

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
    };
  }, [scrollToPreventBounce]);

  const scaleContent = (factor) => {
    if (!pixiApp.current) return;

    // const newScale = contentScale * factor;
    // setContentScale(newScale);

    // Scale the entire stage content, keeping position
    pixiApp.current.stage.scale.set(factor);
  };

  // useEffect(() => {
  //   const handleClick = (e) => {
  //     if (e.button === 0) {
  //       // Left click - make smaller
  //       scaleContent(0.9);
  //     } else if (e.button === 2) {
  //       // Right click - make bigger
  //       scaleContent(1.1);
  //     }
  //   };

  //   containerRef.current?.addEventListener("click", handleClick);
  //   containerRef.current?.addEventListener("contextmenu", (e) => {
  //     e.preventDefault();
  //     handleClick(e);
  //   });

  //   return () => {
  //     containerRef.current?.removeEventListener("click", handleClick);
  //     containerRef.current?.removeEventListener("contextmenu", handleClick);
  //   };
  // }, [contentScale]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        position: "relative",
        WebkitOverflowScrolling: "touch",
        touchAction: "pan-y", // Enable vertical touch scrolling
      }}
      ref={containerRef}
    ></div>
  );
};

export default HandwritingCanvasPixi;
