import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import db from "../utils/db";

import { httpsCallable } from "firebase/functions";

import functions from "../utils/functions";

import { doc, setDoc } from "firebase/firestore";

import { useAuth } from "../App";

import GradeExamPage from "./GradeExamPage";

const ApprovePage = () => {
  let navigate = useNavigate();

  let { user } = useAuth();
  let { userIdProp, examIdProp } = useParams();

  useEffect(() => {
    // Check if the user is one of the specified users, otherwise redirect
    if (
      user.uid !== "uQwUUlHweNhJo4AIRphEgdv6Hqw1" &&
      user.uid !== "6fozyj8RQJO33Q8vWXQLMbYUzct2" &&
      user.uid !== "AoWgLdQMvSfFkqPms5P1fA9UZvZ2"
    ) {
      navigate("/view/");
    }
  }, [user, navigate]);

  return (
    <>
      <GradeExamPage userIdProp={userIdProp} examIdProp={examIdProp} />
    </>
  );
};

export default ApprovePage;
