import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBHyS4rNNNLWH-ic73suKPj5NvTMIOiBUg",
  authDomain: "app.zengrade.com",
  databaseURL:
    "https://magic-ink-742d4-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "magic-ink-742d4",
  storageBucket: "magic-ink-742d4.appspot.com",
  messagingSenderId: "19120305949",
  appId: "1:19120305949:web:7fcff4ccb508018cce93f4",
  measurementId: "G-6Y7376JMEF",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
