import React, { useState, useEffect, forwardRef } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import {
  useDocumentData,
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import { FaMedal, FaInfoCircle } from "react-icons/fa";
import { FcPlus } from "react-icons/fc";

import db from "../utils/db";

import {
  collection,
  query,
  where,
  addDoc,
  doc,
  onSnapshot,
  getDoc,
  setDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";

import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Button,
  Spin,
  notification,
  Space,
  Radio,
  Modal,
  message,
  Typography,
  Row,
  Col,
  Card,
  Tooltip,
  Alert,
  Badge,
} from "antd";

import {
  FileSearchOutlined,
  FileAddOutlined,
  BellOutlined,
  UserOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

import SubscriptionModal from "../components/SubscriptionModal";
import { useSubscription } from "../hooks/useSubscription";
import { useAuth } from "../App";
import functions from "../utils/functions";
import { httpsCallable } from "firebase/functions";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";
import "./LayoutPage.css";
import { useTranslation } from "react-i18next";
import { languageAbbreviations } from "../components/CorrectionMarkDefinition";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const LayoutPage = () => {
  const { user, signout } = useAuth();
  const { t, i18n } = useTranslation();
  const {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  } = useSubscription(user);
  const [menuKey, setMenuKey] = useState(0);
  const location = useLocation();

  const updates = [
    {
      date: new Date("2024-12-08T15:00:00").getTime(),
      points: ["layout.updates.englishLanguage"],
    },
    {
      date: new Date("2024-12-05T10:00:00").getTime(),
      points: ["layout.updates.germanHandwriting"],
    },
    {
      date: new Date("2024-11-26T13:30:00").getTime(),
      points: [t("layout.updates.goodnotes")],
    },
    {
      date: new Date("2024-11-24T14:30:00").getTime(),
      points: [t("layout.updates.annotations")],
    },
    {
      date: new Date("2024-11-21T09:00:00").getTime(),
      points: [
        <span>
          {t("layout.updates.payAsYouGo")}{" "}
          <a
            href="https://zengrade.com/#preise"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("layout.updates.pricing")}
          </a>
        </span>,
      ],
    },
  ];

  const handleLogoutClick = () => {
    signout();
  };

  const [
    subscriptionCollection,
    subscriptionCollectionLoading,
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${user.uid}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [notificationApi, notificationContextHolder] =
    notification.useNotification();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("payment_success") === "true") {
      notificationApi.success({
        message: t(
          "layout.subscription.paymentSuccess.title",
          "Payment Successful"
        ),
        description: t(
          "layout.subscription.paymentSuccess.description",
          "The zenpoints have been successfully credited to your account and are now available."
        ),
        duration: 5,
        placement: "bottom",
      });
      // window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (urlParams.get("setup_success") === "true") {
      notificationApi.success({
        message: t(
          "layout.subscription.setupSuccess.title",
          "Activation Successful"
        ),
        description: t(
          "layout.subscription.setupSuccess.description",
          "zengrade Pay as you go has been successfully activated. Costs are automatically incurred upon upload (€0.25 per page) and are billed monthly."
        ),
        duration: 5,
        placement: "bottom",
      });
      // window.history.replaceState({}, document.title, window.location.pathname);
    }

    if (urlParams.get("creation_success") === "true") {
      const closeNotification = () => {
        notificationApi.destroy();
      };

      notificationApi.warning({
        message: t(
          "layout.subscription.creationSuccess.title",
          "Rubrics Successfully Created"
        ),
        type: "info",
        style: { width: "100%", marginTop: "50px" },
        description: (
          <div
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            <Alert
              message={t("upload.requirements.scanRecommendation")}
              showIcon={false}
              style={{ marginBottom: "20px", width: "100%" }}
            />
            <div>
              <p>
                <b>{t("upload.pdfUpload.optimalScan")}</b>
              </p>
              <ul>
                <li>{t("upload.pdfUpload.scanFeatures.multifunction")}</li>
                <li>{t("upload.pdfUpload.scanFeatures.resolution")}</li>
                <li>{t("upload.pdfUpload.scanFeatures.color")}</li>
                <li>{t("upload.pdfUpload.scanFeatures.format")}</li>
                <li>{t("upload.pdfUpload.scanFeatures.order")}</li>
                <li>{t("upload.pdfUpload.scanFeatures.rotation")}</li>
              </ul>
            </div>
            <br />
            <small>{t("upload.pdfUpload.scannerNote")}</small>
            <br />
            <br />
            <p>
              <b>{t("upload.pdfUpload.optimalDocument")}</b>
            </p>
            <div>
              <ul>
                <li>{t("upload.pdfUpload.documentFeatures.handwritten")}</li>
                <li>{t("upload.pdfUpload.documentFeatures.blue")}</li>
                <li>{t("upload.pdfUpload.documentFeatures.lineatur")}</li>
                <li>{t("upload.pdfUpload.documentFeatures.singleTask")}</li>
              </ul>
            </div>
            <p>
              <b>
                {t("upload.requirements.emailInstructions")}{" "}
                <a
                  href={
                    i18n.language === "en"
                      ? "https://youtu.be/uW4Us1MG4aQ?si=XOsmzkAOb2CtIqoV"
                      : "https://youtu.be/y9XTEAN-okQ?si=ecuCFK1ONtXq1AYW"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("viewExams.welcome.links.tutorial")}
                </a>{" "}
                {t("upload.requirements.showVideo")}
              </b>
            </p>
            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <Button type="primary" onClick={closeNotification}>
                {t("modal.close")}
              </Button>
            </div>
          </div>
        ),
        duration: 0,
        placement: "top",
      });
    }
  }, [notificationApi, t]);

  const buyAdditionalZenpoints = async () => {
    message.loading("Sie werden weitergeleitet...", 15);

    try {
      const priceId =
        activeSubscriptionName === "Gold"
          ? "price_1PlTbRIBcEHZy5P9Z5wAn3OM"
          : "price_1PlTbZIBcEHZy5P9lPu6rgf8";

      const docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        {
          mode: "payment",
          line_items: [
            {
              price: priceId,
              quantity: 1,
              adjustable_quantity: {
                enabled: true,
                minimum: 1,
                maximum: 1000,
              },
            },
          ],
          success_url: window.location.origin + "?payment_success=true",
          cancel_url: window.location.origin,
          client_reference_id: user.uid,
          automatic_tax: { enabled: true },
          allow_promotion_codes: true,
          locale: "de",
          invoice_creation: {
            enabled: true,
          },
        }
      );

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occurred: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      message.error(
        "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
      );
    }
  };

  const hasActiveSubscription =
    subscriptionCollection &&
    subscriptionCollection.docs.length > 0 &&
    subscriptionCollection.docs.some((doc) => doc.data().status === "active");

  const [activeSubscriptionName, setActiveSubscriptionName] = useState("");

  useEffect(() => {
    const getSubscriptionName = async () => {
      if (hasActiveSubscription && subscriptionCollection) {
        const activeSubscription = subscriptionCollection.docs.find(
          (doc) => doc.data().status === "active"
        );

        if (activeSubscription) {
          const subscriptionData = activeSubscription.data();
          const priceId = subscriptionData.items[0]?.price?.id;

          if (!priceId) {
            setActiveSubscriptionName("Unbekannt");
            return;
          }

          const silberPriceId = "price_1PlTbiIBcEHZy5P9GqesgWVy";
          const goldPriceId = "price_1PlTbdIBcEHZy5P9rUiZxVA0";
          const payAsYouGoPriceId = "price_1QLqTRIBcEHZy5P9tiVB24Hq";

          switch (priceId) {
            case silberPriceId:
              setActiveSubscriptionName("Silber");
              break;
            case goldPriceId:
              setActiveSubscriptionName("Gold");
              break;
            case payAsYouGoPriceId:
              setActiveSubscriptionName("Pay as you go");
              break;
            default:
              console.log(`Unexpected price ID: ${priceId}`);
              setActiveSubscriptionName("Unbekannt");
          }
        } else {
          setActiveSubscriptionName("");
        }
      } else {
        setActiveSubscriptionName("");
      }
    };

    getSubscriptionName();
  }, [hasActiveSubscription, subscriptionCollection]);

  const [loadingPortal, setLoadingPortal] = useState(false);

  const goToCustomerPortal = async () => {
    message.loading("Sie werden weitergeleitet...", 15);

    setLoadingPortal(true);
    try {
      const customerDoc = await getDoc(doc(db, "customers", user.uid));
      const stripeCustomerId = customerDoc.data()?.stripeId;

      if (!stripeCustomerId) {
        throw new Error("Stripe customer ID not found");
      } else {
        console.log(`stripeCustomerId: ${stripeCustomerId}`);
      }

      const createCustomStripePortalSession = httpsCallable(
        functions,
        "createCustomStripePortalSession"
      );
      const { data } = await createCustomStripePortalSession({
        customerId: stripeCustomerId,
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error("Error:", error);
      message.error("Fehler beim Abrufen des Kundenportals.");
    } finally {
      setLoadingPortal(false);
    }
  };

  const totalCredits =
    (userData?.subscriptionCredits || 0) + (userData?.permanentCredits || 0);

  const calculateCosts = (pages) => {
    const costPerPage = 0.25;
    return (pages * costPerPage).toFixed(2);
  };

  const [isNewsModalVisible, setIsNewsModalVisible] = useState(false);
  const [hasUnreadUpdates, setHasUnreadUpdates] = useState(false);

  const handleNewsClick = async () => {
    setIsNewsModalVisible(true);

    try {
      await setDoc(
        doc(db, "users", user.uid),
        {
          lastReadUpdates: new Date().getTime(),
        },
        { merge: true }
      );
    } catch (error) {
      console.error("Error updating lastReadUpdates:", error);
    }
  };

  useEffect(() => {
    if (userData) {
      const lastReadTimestamp = userData.lastReadUpdates || 0;
      const hasNewUpdates = updates.some(
        (update) => update.date > lastReadTimestamp
      );
      setHasUnreadUpdates(hasNewUpdates);
    }
  }, [userData]);

  useEffect(() => {
    setMenuKey((prev) => prev + 1);
  }, [i18n.language]);

  const handleLanguageChange = async (lang) => {
    try {
      if (user) {
        i18n.changeLanguage(lang);

        const userRef = doc(db, "users", user.uid);

        // Get current exam data
        const examsRef = collection(db, `users/${user.uid}/exams`);
        const examsSnapshot = await getDocs(examsRef);

        // Update each exam's correction marks
        const updatePromises = examsSnapshot.docs.map(async (examDoc) => {
          const examData = examDoc.data();
          if (examData.correctionMarks) {
            const updatedMarks = examData.correctionMarks.map((mark) => {
              if (
                mark.annotationCategory === "linguistic" &&
                mark.abbreviation in languageAbbreviations[lang]
              ) {
                return {
                  ...mark,
                  customAbbreviation:
                    languageAbbreviations[lang][mark.abbreviation],
                };
              }
              return mark;
            });

            const examRef = doc(db, `users/${user.uid}/exams/${examDoc.id}`);
            return updateDoc(examRef, { correctionMarks: updatedMarks });
          }
          return Promise.resolve();
        });

        // Wait for all updates to complete
        await Promise.all([
          setDoc(userRef, { language: lang }, { merge: true }),
          ...updatePromises,
        ]);
      }
    } catch (error) {
      console.error("Error saving language preference:", error);
    }
  };

  const newsMenuItem = {
    key: "news",
    icon: (
      <Badge dot={hasUnreadUpdates}>
        <BellOutlined />
      </Badge>
    ),
    label: "Updates",
    onClick: handleNewsClick,
  };

  const languageMenu = {
    key: "language",
    label: (
      <Dropdown
        menu={{
          items: [
            {
              key: "en",
              label: "English",
              onClick: () => handleLanguageChange("en"),
            },
            {
              key: "de",
              label: "Deutsch",
              onClick: () => handleLanguageChange("de"),
            },
          ],
        }}
        trigger={["click"]}
      >
        <Space>
          <GlobalOutlined />
          {i18n.language.toUpperCase()}
        </Space>
      </Dropdown>
    ),
  };

  const menuItems = React.useMemo(
    () => [
      {
        key: "1",
        icon: <FileAddOutlined />,
        label: <Link to="/create">{t("layout.menu.uploadExam")}</Link>,
      },
      {
        key: "2",
        icon: <FileSearchOutlined />,
        label: <Link to="/view">{t("layout.menu.viewExams")}</Link>,
        className:
          location.pathname.includes("/view") || location.pathname === "/"
            ? "ant-menu-item-selected"
            : "",
      },
      newsMenuItem,
      languageMenu,
      {
        key: "3",
        label: (
          <div style={{ display: "inline-block" }}>
            <Dropdown
              menu={{
                items: [
                  {
                    key: "info",
                    label: (
                      <center>
                        <b>{user.email}</b>
                        {hasActiveSubscription && <br />}
                        {hasActiveSubscription && (
                          <small
                            onClick={goToCustomerPortal}
                            style={{ cursor: "pointer" }}
                          >
                            <Tooltip
                              title={t("layout.subscription.stripePortal")}
                            >
                              <span style={{ textDecoration: "underline" }}>
                                zengrade{" "}
                                {activeSubscriptionName &&
                                activeSubscriptionName !== "Pay as you go"
                                  ? t(
                                      `layout.subscription.${activeSubscriptionName.toLowerCase()}`
                                    )
                                  : ""}
                                {activeSubscriptionName &&
                                activeSubscriptionName.includes("Pay as you go")
                                  ? "Pay as you go"
                                  : ""}
                              </span>
                            </Tooltip>
                            <FaMedal
                              style={{
                                color:
                                  activeSubscriptionName === "Pay as you go"
                                    ? "gold"
                                    : activeSubscriptionName === "Silber"
                                    ? "silver"
                                    : "gold",
                              }}
                            />
                          </small>
                        )}
                        <br />
                        <br />
                        {activeSubscriptionName === "Pay as you go" && (
                          <>
                            <small>
                              {t("layout.subscription.currentPeriod")}
                              <br />
                              {t("layout.subscription.usedPages", {
                                count: userData?.usedPages || 0,
                              })}
                              <br />
                              <span>
                                {t("layout.subscription.costs", {
                                  amount: calculateCosts(
                                    userData?.usedPages || 0
                                  ),
                                })}
                              </span>
                            </small>
                            {userData?.permanentCredits > 0 && (
                              <>
                                <br />
                                <small>
                                  {t("layout.subscription.freePages", {
                                    count: userData?.permanentCredits,
                                  })}
                                </small>
                              </>
                            )}
                            <br />
                            <br />
                          </>
                        )}
                        {hasActiveSubscription &&
                          activeSubscriptionName !== "Pay as you go" && (
                            <>
                              {userData?.subscriptionCredits > 0 && (
                                <small>
                                  <Tooltip
                                    title={t(
                                      "layout.subscription.subscriptionCredits"
                                    )}
                                  >
                                    <FaInfoCircle />
                                  </Tooltip>{" "}
                                  zenpoints{" "}
                                  <FaMedal
                                    style={{
                                      color:
                                        activeSubscriptionName === "Silber"
                                          ? "silver"
                                          : "gold",
                                    }}
                                  />{" "}
                                  {userData?.subscriptionCredits || 0}
                                </small>
                              )}
                              <br />
                              {userData?.permanentCredits > 0 && (
                                <small>
                                  <Tooltip
                                    title={t(
                                      "layout.subscription.permanentCredits"
                                    )}
                                  >
                                    <FaInfoCircle />
                                  </Tooltip>{" "}
                                  zenpoints{" "}
                                  <FcPlus style={{ color: "green" }} />{" "}
                                  {userData?.permanentCredits}
                                </small>
                              )}
                            </>
                          )}
                      </center>
                    ),
                  },
                  // {
                  //   key: "customerPortal",
                  //   label: (
                  //     <div
                  //       style={{ textAlign: "center", cursor: "pointer" }}
                  //       onClick={goToCustomerPortal}
                  //     >
                  //       {t("layout.menu.customerPortal")}
                  //     </div>
                  //   ),
                  // },
                  {
                    key: "signOut",
                    label: (
                      <div
                        style={{ textAlign: "center" }}
                        onClick={handleLogoutClick}
                      >
                        {t("layout.menu.signOut")}
                      </div>
                    ),
                  },
                ],
                key: `user-menu-${menuKey}`,
              }}
              placement="bottomRight"
              trigger={["click"]}
            >
              <Avatar style={{ cursor: "pointer" }} icon={<UserOutlined />} />
            </Dropdown>
          </div>
        ),
      },
      !hasActiveSubscription && userData?.isRestricted
        ? {
            key: "4",
            label: (
              <div style={{ display: "inline-block" }}>
                <Button
                  shape="round"
                  type="primary"
                  className="user-menu-button"
                  onClick={showSubscriptionModal}
                  size="small"
                >
                  Pay as you go
                </Button>
              </div>
            ),
          }
        : null,
      ...(activeSubscriptionName !== "Pay as you go" &&
      userData?.subscriptionCredits &&
      userData?.subscriptionCredits > 0
        ? [
            {
              key: "4",
              disabled: true,
              className: "zenpoints-display",
              style: { cursor: "default" },
              label: <span>zenpoints: {totalCredits}</span>,
            },
          ]
        : []),
    ],
    [
      t,
      location.pathname,
      newsMenuItem,
      user.email,
      hasActiveSubscription,
      activeSubscriptionName,
      userData,
      totalCredits,
      handleLogoutClick,
      goToCustomerPortal,
      menuKey,
      i18n.language,
      loadingStripe,
      isSubscriptionModalVisible,
      showSubscriptionModal,
    ]
  );

  const UpdatesModal = () => (
    <Modal
      title={t("layout.updates.title")}
      open={isNewsModalVisible}
      onCancel={() => setIsNewsModalVisible(false)}
      footer={null}
      width={800}
    >
      {updates.map((update, index) => (
        <Card key={index} style={{ marginBottom: 16 }}>
          <Row>
            <Col span={24}>
              <Text type="secondary">
                {new Date(update.date).toLocaleDateString(
                  i18n.language === "de" ? "de-DE" : "en-US"
                )}
              </Text>
              <br />
              {update.points.map((point, pointIndex) => (
                <div key={pointIndex} style={{ marginTop: 8 }}>
                  {typeof point === "string" ? t(point) : point}
                </div>
              ))}
            </Col>
          </Row>
        </Card>
      ))}
    </Modal>
  );

  return (
    <Layout>
      {notificationContextHolder}
      <Header style={{ backgroundColor: "#35393C" }}>
        <div className="company-logo">
          <img src={ZengradeLogo} alt="logo" className="logo-img" />
          zengrade
        </div>
        {user && (
          <React.Fragment>
            <Menu
              style={{ backgroundColor: "#35393C" }}
              theme="dark"
              mode="horizontal"
              overflowedIndicator={<></>}
              items={menuItems}
              key={`menu-${menuKey}`}
            />
          </React.Fragment>
        )}
      </Header>
      <div className="content">
        <Spin spinning={loadingStripe || loadingPortal}>
          <Outlet />
        </Spin>
      </div>
      <SubscriptionModal
        isOpen={isSubscriptionModalVisible}
        onCancel={handleSubscriptionModalCancel}
        onSubscribe={handleSubscribe}
      />
      <UpdatesModal />
    </Layout>
  );
};

export default LayoutPage;
