import React, { useState, useEffect, useContext, createContext, Suspense } from "react";
import CacheBuster from "react-cache-buster";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import { usePostHog } from "posthog-js/react";
import * as Sentry from "@sentry/react";

import { useLocation, Navigate } from "react-router-dom";
import LayoutPage from "./pages/LayoutPage";
import ViewExamsPage from "./pages/ViewExamsPage";
import CreateExamPage from "./pages/CreateExamPage";
import NotFoundPage from "./pages/NotFoundPage";
import DeleteExamPage from "./pages/DeleteExamPage";
import LoginPage from "./pages/LoginPage";
import EmailVerificationPage from "./pages/EmailVerificationPage";
// import TaskLabelPage from "./pages/TaskLabelPage";
// import PDFSplitterPage from "./pages/PDFSplitterPage";
import auth from "./utils/auth";
import PDFUploadPage from "./pages/PDFUploadPage";
import GradeExamPage from "./pages/GradeExamPage";
import StatusPage from "./pages/StatusPage";
import ApprovePage from "./pages/ApprovePage";
import AdminPage from "./pages/AdminPage";
import TextUploadPage from "./pages/TextUploadPage";
import EditExamPage from "./pages/EditExamPage";

import packageJson from "../package.json";
import UploadChoice from "./pages/UploadChoice";
import PixiPage from "./pages/PixiPage";
import GradeExamPagePixi from "./pages/GradeExamPagePixi";
import GradeExamPagePhaser from "./pages/GradeExamPagePhaser";
const { version } = packageJson;

// import CheckHandwritingPage from "./pages/CheckHandwritingPage";

const AuthContext = createContext(null);

export const App = () => {
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => setCurrentLang(lng));
    return () => {
      i18n.off('languageChanged');
    };
  }, []);

  return (
    <I18nextProvider i18n={i18n} key={currentLang}>
      <Suspense fallback="Loading...">
        <CacheBuster
          currentVersion={version}
          isEnabled={true}
          isVerboseMode={false}
          metaFileDirectory={"."}
        >
          <AuthProvider>
            <AppContent />
          </AuthProvider>
        </CacheBuster>
      </Suspense>
    </I18nextProvider>
  );
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const signout = (callback) => {
    auth
      .signOut()
      .then(function () {
        setUser(null);
        callback();
      })
      .catch(function () {
        console.log("Error Signing out");
      });
  };

  const value = { user, signout };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

// src/App.js
export const RequireAuth = ({ children }) => {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    // Redirect to /login if not logged in
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // if (!user.emailVerified) {
  //   // Redirect to /verify-email if email is not verified
  //   return <Navigate to="/verify-email" state={{ from: location }} replace />;
  // }

  return children;
};

const AppContent = () => {
  const posthog = usePostHog();
  const { user } = useAuth();

  useEffect(() => {
    if (user && posthog) {
      if (window.location.hostname === "localhost") {
        posthog.opt_out_capturing();
      }

      posthog.identify(user.uid, {
        email: user.email,
      });

      Sentry.setUser({
        id: user.uid,
        email: user.email,
      });
    } else {
      Sentry.setUser(null); // Clear user data when not authenticated
    }
  }, [posthog, user]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <React.Fragment>
        <Route path="verify-email" element={<EmailVerificationPage />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <LayoutPage />
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <RequireAuth>
                <ViewExamsPage />
              </RequireAuth>
            }
          />
          <Route
            path="view"
            element={
              <RequireAuth>
                <ViewExamsPage />
              </RequireAuth>
            }
          />
          <Route
            path="grade/:examIdProp"
            element={
              <RequireAuth>
                <GradeExamPage />
              </RequireAuth>
            }
          />
          <Route
            path="pixi/:examIdProp"
            element={
              <RequireAuth>
                <PixiPage />
              </RequireAuth>
            }
          />
          <Route
            path="grade-pixi/:examIdProp"
            element={
              <RequireAuth>
                <GradeExamPagePixi />
              </RequireAuth>
            }
          />
          <Route
            path="grade-phaser/:examIdProp"
            element={
              <RequireAuth>
                <GradeExamPagePhaser />
              </RequireAuth>
            }
          />
          <Route
            path="status/:examIdProp"
            element={
              <RequireAuth>
                <StatusPage />
              </RequireAuth>
            }
          />
          {/* <Route
            path="label/:examIdProp"
            element={
              <RequireAuth>
                <TaskLabelPage />
              </RequireAuth>
            }
          /> */}
          <Route
            path="upload/:examIdProp"
            element={
              <RequireAuth>
                <PDFUploadPage />
              </RequireAuth>
            }
          />
          <Route
            path="upload-text/:examIdProp"
            element={
              <RequireAuth>
                <TextUploadPage />
              </RequireAuth>
            }
          />
          <Route
            path="upload-choice/:examIdProp"
            element={
              <RequireAuth>
                <UploadChoice />
              </RequireAuth>
            }
          />
          {/* <Route
            path="split/:examIdProp"
            element={
              <RequireAuth>
                <PDFSplitterPage />
              </RequireAuth>
            }
          /> */}
          <Route
            path="delete/:examIdProp"
            element={
              <RequireAuth>
                <DeleteExamPage />
              </RequireAuth>
            }
          />
          <Route
            path="edit/:examIdProp"
            element={
              <RequireAuth>
                <EditExamPage />
              </RequireAuth>
            }
          />
          <Route
            path="create"
            element={
              <RequireAuth>
                <CreateExamPage />
              </RequireAuth>
            }
          />
          {/* <Route
            path="check/:userIdProp/:examIdProp"
            element={
              <RequireAuth>
                <CheckHandwritingPage />
              </RequireAuth>
            }
          /> */}
          <Route
            path="approve/:userIdProp/:examIdProp"
            element={
              <RequireAuth>
                <ApprovePage />
              </RequireAuth>
            }
          />
          <Route
            path="admin/:userIdProp/:examIdProp"
            element={
              <RequireAuth>
                <AdminPage />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
        <Route path="login" element={<LoginPage />} />
      </React.Fragment>
    )
  );

  return <RouterProvider router={router} />;
};
