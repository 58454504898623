import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Table,
  ColorPicker,
  Typography,
  Popover,
  Space,
} from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { doc, updateDoc } from "firebase/firestore";
import db from "../utils/db";
import { useAuth } from "../App";

const { Title, Text } = Typography;

export const languageAbbreviations = {
  de: {
    // Spelling
    R: "R",
    // Grammar
    G: "G",
    "G(P)": "G(P)",
    "G(Flx)": "G(Flx)",
    "G(T)": "G(T)",
    "G(B)": "G(B)",
    "G(M)": "G(M)",
    "G(√)": "G(√)",
    "G(-)": "G(-)",
    // Punctuation
    Z: "Z",
    "Z(√)": "Z(√)",
    "Z(-)": "Z(-)",
    // Expression
    A: "A",
    "A(√)": "A(√)",
    "A(-)": "A(-)",
    "A(ugs.)": "A(ugs.)",
    "A(Wdh.)": "A(Wdh.)",
    "A(Id)": "A(Id)",
    "A(St)": "A(St)",
    // Syntax
    Sb: "Sb",
    "Sb(√)": "Sb(√)",
    "Sb(-)": "Sb(-)",
    // Positive
    "Sp+": "Sp+",
  },
  en: {
    // Spelling
    R: "SP",
    // Grammar
    G: "GR",
    "G(P)": "GR(P)",
    "G(Flx)": "GR(F)",
    "G(T)": "GR(T)",
    "G(B)": "GR(R)",
    "G(M)": "GR(M)",
    "G(√)": "GR(+)",
    "G(-)": "GR(-)",
    // Punctuation
    Z: "P",
    "Z(√)": "P(+)",
    "Z(-)": "P(-)",
    // Expression
    A: "WC",
    "A(√)": "WC(+)",
    "A(-)": "WC(-)",
    "A(ugs.)": "WC(inf)",
    "A(Wdh.)": "WC(rep)",
    "A(Id)": "WC(id)",
    "A(St)": "WC(st)",
    // Syntax
    Sb: "SY",
    "Sb(√)": "SY(+)",
    "Sb(-)": "SY(-)",
    // Positive
    "Sp+": "L+",
  },
};

const defaultCorrectionMarks = [
  {
    annotationCategory: "linguistic",
    category: "Handschrift",
    type: "Handschrift (kein Fehler)",
    abbreviation: "H",
    color: "#8699a1",
    customAbbreviation: "H",
    customColor: "#8699a1",
  },
  {
    annotationCategory: "linguistic",
    category: "Rechtschreibung",
    type: "Rechtschreibfehler",
    abbreviation: "R",
    color: "#ff4d4f",
    customAbbreviation: "R",
    customColor: "#ff4d4f",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Grammatikfehler",
    abbreviation: "G",
    color: "#594DFF",
    customAbbreviation: "G",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Präpositionsfehler",
    abbreviation: "G(P)",
    color: "#594DFF",
    customAbbreviation: "G(P)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falsche Flexion",
    abbreviation: "G(Flx)",
    color: "#594DFF",
    customAbbreviation: "G(Flx)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falsches Tempus",
    abbreviation: "G(T)",
    color: "#594DFF",
    customAbbreviation: "G(T)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falscher Bezug",
    abbreviation: "G(B)",
    color: "#594DFF",
    customAbbreviation: "G(B)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "falscher Modus",
    abbreviation: "G(M)",
    color: "#594DFF",
    customAbbreviation: "G(M)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Ergänzung von grammatisch Fehlendem",
    abbreviation: "G(√)",
    color: "#594DFF",
    customAbbreviation: "G(√)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Grammatik",
    type: "Streichung von grammatisch Überflüssigem",
    abbreviation: "G(-)",
    color: "#594DFF",
    customAbbreviation: "G(-)",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Veränderung eines Satzzeichens",
    abbreviation: "Z",
    color: "#F406BC",
    customAbbreviation: "Z",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Ergänzung eines Satzzeichens",
    abbreviation: "Z(√)",
    color: "#F406BC",
    customAbbreviation: "Z(√)",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Zeichensetzung",
    type: "Streichung eines Satzzeichens",
    abbreviation: "Z(-)",
    color: "#F406BC",
    customAbbreviation: "Z(-)",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "falsches Wort, unpassendes Wort, falsche Wortwahl, lexikalischer Fehler",
    abbreviation: "A",
    color: "#F98F31",
    customAbbreviation: "A",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Ergänzung von semantisch Fehlendem",
    abbreviation: "A(√)",
    color: "#F98F31",
    customAbbreviation: "A(√)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Streichung von semantisch Überflüssigem",
    abbreviation: "A(-)",
    color: "#F98F31",
    customAbbreviation: "A(-)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "umgangssprachlicher, unangemessener Ausdruck",
    abbreviation: "A(ugs.)",
    color: "#F98F31",
    customAbbreviation: "A(ugs.)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "Ausdruckswiederholung, mangelnde Variabilität",
    abbreviation: "A(Wdh.)",
    color: "#F98F31",
    customAbbreviation: "A(Wdh.)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "falsches Idiom",
    abbreviation: "A(Id)",
    color: "#F98F31",
    customAbbreviation: "A(Id)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Ausdruck",
    type: "nicht textsortengerechter Stil, unangemessener Stil",
    abbreviation: "A(St)",
    color: "#F98F31",
    customAbbreviation: "A(St)",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "falscher Satzbau",
    abbreviation: "Sb",
    color: "#C9CF35",
    customAbbreviation: "Sb",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "Ergänzung von syntaktisch Fehlendem",
    abbreviation: "Sb(√)",
    color: "#C9CF35",
    customAbbreviation: "Sb(√)",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Satzbau",
    type: "Streichung von syntaktisch Überflüssigem",
    abbreviation: "Sb(-)",
    color: "#C9CF35",
    customAbbreviation: "Sb(-)",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "linguistic",
    category: "Positiv",
    type: "Sprache positiv",
    abbreviation: "Sp+",
    color: "#059F09",
    customAbbreviation: "Sp+",
    customColor: "#059F09",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich richtig",
    color: "#059F09",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#059F09",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich falsch",
    color: "#ff4d4f",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#ff4d4f",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich ungenau",
    color: "#594DFF",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#594DFF",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich unlogisch",
    color: "#F406BC",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#F406BC",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich unvollständig",
    color: "#C9CF35",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#C9CF35",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltliche Wiederholung",
    color: "#F98F31",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#F98F31",
  },
  {
    annotationCategory: "content",
    category: "Inhalt",
    type: "inhaltlich irrelevant",
    color: "#8699a1",
    abbreviation: "I",
    customAbbreviation: "I",
    customColor: "#8699a1",
  },
];

const CorrectionMarkDefinition = ({
  visible,
  onClose,
  examData,
  examIdProp,
  annotations,
  showFrequencies,
}) => {
  const { t, i18n } = useTranslation();
  const [correctionMarks, setCorrectionMarks] = useState(null);
  const [initialCorrectionMarks, setInitialCorrectionMarks] = useState(null);

  const [editingLanguageIndex, setEditingLanguageIndex] = useState(null);
  const [editingContentIndex, setEditingContentIndex] = useState(null);

  const [inputError, setInputError] = useState(false);
  const [tempEditValue, setTempEditValue] = useState(null);

  const [filteredIndices, setFilteredIndices] = useState([]);

  useEffect(() => {
    if (correctionMarks) {
      const newFilteredIndices = correctionMarks
        .map((mark, index) => ({ mark, index }))
        .filter(
          (item) =>
            item.mark.annotationCategory === "linguistic" &&
            !item.mark.type.includes("H")
        )
        .map((item) => item.index);
      setFilteredIndices(newFilteredIndices);
    }
  }, [correctionMarks]);

  const handleEdit = (displayIndex, category) => {
    const actualIndex =
      category === "linguistic" ? filteredIndices[displayIndex] : displayIndex;
    if (category === "linguistic") {
      setEditingLanguageIndex(actualIndex);
    } else {
      setEditingContentIndex(actualIndex);
    }
    setTempEditValue({
      customColor: correctionMarks[actualIndex].customColor,
      customAbbreviation: correctionMarks[actualIndex].customAbbreviation,
    });
  };

  const handleSave = (index, category) => {
    if (category === "linguistic") {
      setEditingLanguageIndex(null);
    } else {
      setEditingContentIndex(null);
    }
    setInputError(false);

    if (
      JSON.stringify(correctionMarks) !== JSON.stringify(initialCorrectionMarks)
    ) {
      saveCorrectionMarks();
    }
  };

  const handlePopoverVisibleChange = (visible, category, displayIndex) => {
    if (!visible) {
      const actualIndex =
        category === "linguistic"
          ? filteredIndices[displayIndex]
          : displayIndex;
      handleSave(actualIndex, category);
    }
  };

  const handleCancel = (category) => {
    if (category === "linguistic") {
      setCorrectionMarks((prevMarks) => {
        const newMarks = [...prevMarks];
        newMarks[editingLanguageIndex] = {
          ...newMarks[editingLanguageIndex],
          ...tempEditValue,
        };
        return newMarks;
      });
      setEditingLanguageIndex(null);
    } else {
      setCorrectionMarks((prevMarks) => {
        const newMarks = [...prevMarks];
        newMarks[editingContentIndex] = {
          ...newMarks[editingContentIndex],
          ...tempEditValue,
        };
        return newMarks;
      });
      setEditingContentIndex(null);
    }
    setInputError(false);
    setTempEditValue(null);
  };

  const handleColorChange = (color, index, category) => {
    const hexColor = color.toHexString();
    setCorrectionMarks((prevMarks) => {
      const newMarks = [...prevMarks];
      const categoryMarks = newMarks.filter(
        (mark) => mark.annotationCategory === category
      );
      const globalIndex = newMarks.findIndex(
        (mark) => mark === categoryMarks[index]
      );

      if (globalIndex !== -1) {
        newMarks[globalIndex] = {
          ...newMarks[globalIndex],
          customColor: hexColor,
        };
      }
      return newMarks;
    });
    setTempEditValue((prev) => ({ ...prev, customColor: hexColor }));
  };

  const handleAbbreviationChange = (value, index, category) => {
    const trimmedValue = value.trim().slice(0, 7);
    setCorrectionMarks((prevMarks) => {
      const newMarks = [...prevMarks];
      newMarks[index] = {
        ...newMarks[index],
        customAbbreviation: trimmedValue,
      };
      return newMarks;
    });
    setTempEditValue((prev) => ({
      ...prev,
      customAbbreviation: trimmedValue,
    }));
    setInputError(trimmedValue.length === 0);
  };

  useEffect(() => {
    let marks;
    if (
      examData?.correctionMarks &&
      examData.correctionMarks.every((mark) => mark.customAbbreviation)
    ) {
      marks = examData.correctionMarks;
    } else {
      marks = [...defaultCorrectionMarks];
    }

    setCorrectionMarks(JSON.parse(JSON.stringify(marks)));
    setInitialCorrectionMarks(JSON.parse(JSON.stringify(marks)));
  }, [examData]);

  const { user } = useAuth();

  const saveCorrectionMarks = async (marksToSave = correctionMarks) => {
    const docRef = doc(db, `users/${user.uid}/exams/${examIdProp}`);
    try {
      await updateDoc(docRef, { correctionMarks: marksToSave });
      setInitialCorrectionMarks(JSON.parse(JSON.stringify(marksToSave)));
    } catch (error) {
      console.error("Failed to save correction marks:", error);
    }
  };

  const columnsLanguage = [
    {
      title: t("correctionMarks.columns.mark"),
      dataIndex: "customAbbreviation",
      width: 100,
      render: (text, record, displayIndex) => (
        <Popover
          content={
            editingLanguageIndex === filteredIndices[displayIndex] ? (
              <Space direction="vertical" align="center">
                <Space direction="horizontal">
                  <ColorPicker
                    value={record.customColor}
                    onChange={(color) =>
                      handleColorChange(
                        color,
                        filteredIndices[displayIndex],
                        record.annotationCategory
                      )
                    }
                  />
                  <Input
                    value={record.customAbbreviation}
                    onChange={(e) =>
                      handleAbbreviationChange(
                        e.target.value,
                        filteredIndices[displayIndex],
                        "linguistic"
                      )
                    }
                    maxLength={7}
                    style={{ marginTop: 8, width: 90 }}
                    status={inputError ? "error" : ""}
                  />
                  <Button
                    onClick={() =>
                      handleSave(filteredIndices[displayIndex], "linguistic")
                    }
                    style={{ marginTop: 8 }}
                    icon={<SaveOutlined />}
                    disabled={
                      inputError ||
                      record.customAbbreviation.trim().length === 0
                    }
                  />
                  <Button
                    onClick={() => handleCancel("linguistic")}
                    style={{ marginTop: 8 }}
                    icon={<CloseCircleOutlined />}
                  />
                </Space>
                <Text
                  type="secondary"
                  style={{
                    fontSize: "0.8em",
                    textAlign: "center",
                    color: inputError ? "red" : "",
                  }}
                >
                  {inputError
                    ? t("correctionMarks.editor.inputRequired")
                    : t("correctionMarks.editor.maxChars")}
                </Text>
              </Space>
            ) : null
          }
          trigger="click"
          open={editingLanguageIndex === filteredIndices[displayIndex]}
          onVisibleChange={(visible) =>
            handlePopoverVisibleChange(visible, "linguistic", displayIndex)
          }
        >
          <Text
            style={{
              color: record.customColor,
              fontSize: "1.2em",
              fontFamily: "Times New Roman",
              cursor: "pointer",
            }}
            onClick={() => handleEdit(displayIndex, "linguistic")}
          >
            {text}
          </Text>
        </Popover>
      ),
    },
    {
      title: t("correctionMarks.columns.meaning"),
      dataIndex: "type",
      render: (text, record) => {
        let translationKey;
        switch (text) {
          // Handschrift
          case "Handschrift (kein Fehler)":
            translationKey = "correctionMarks.categories.handwriting.noError";
            break;
          // Rechtschreibung
          case "Rechtschreibfehler":
            translationKey = "correctionMarks.categories.spelling.error";
            break;
          // Grammatik
          case "Grammatikfehler":
            translationKey = "correctionMarks.categories.grammar.error";
            break;
          case "Präpositionsfehler":
            translationKey = "correctionMarks.categories.grammar.preposition";
            break;
          case "falsche Flexion":
            translationKey = "correctionMarks.categories.grammar.flexion";
            break;
          case "falsches Tempus":
            translationKey = "correctionMarks.categories.grammar.tense";
            break;
          case "falscher Bezug":
            translationKey = "correctionMarks.categories.grammar.reference";
            break;
          case "falscher Modus":
            translationKey = "correctionMarks.categories.grammar.mode";
            break;
          case "Ergänzung von grammatisch Fehlendem":
            translationKey = "correctionMarks.categories.grammar.addMissing";
            break;
          case "Streichung von grammatisch Überflüssigem":
            translationKey =
              "correctionMarks.categories.grammar.removeSuperfluous";
            break;
          // Zeichensetzung
          case "Veränderung eines Satzzeichens":
            translationKey = "correctionMarks.categories.punctuation.change";
            break;
          case "Ergänzung eines Satzzeichens":
            translationKey = "correctionMarks.categories.punctuation.add";
            break;
          case "Streichung eines Satzzeichens":
            translationKey = "correctionMarks.categories.punctuation.remove";
            break;
          // Ausdruck
          case "falsches Wort, unpassendes Wort, falsche Wortwahl, lexikalischer Fehler":
            translationKey = "correctionMarks.categories.expression.wrongWord";
            break;
          case "Ergänzung von semantisch Fehlendem":
            translationKey = "correctionMarks.categories.expression.addMissing";
            break;
          case "Streichung von semantisch Überflüssigem":
            translationKey =
              "correctionMarks.categories.expression.removeSuperfluous";
            break;
          case "umgangssprachlicher, unangemessener Ausdruck":
            translationKey = "correctionMarks.categories.expression.colloquial";
            break;
          case "Ausdruckswiederholung, mangelnde Variabilität":
            translationKey = "correctionMarks.categories.expression.repetition";
            break;
          case "falsches Idiom":
            translationKey = "correctionMarks.categories.expression.idiom";
            break;
          case "nicht textsortengerechter Stil, unangemessener Stil":
            translationKey = "correctionMarks.categories.expression.style";
            break;
          // Satzbau
          case "falscher Satzbau":
            translationKey = "correctionMarks.categories.syntax.error";
            break;
          case "Ergänzung von syntaktisch Fehlendem":
            translationKey = "correctionMarks.categories.syntax.addMissing";
            break;
          case "Streichung von syntaktisch Überflüssigem":
            translationKey =
              "correctionMarks.categories.syntax.removeSuperfluous";
            break;
          // Positiv
          case "Sprache positiv":
            translationKey = "correctionMarks.categories.positive.language";
            break;
          // Inhalt
          case "inhaltlich richtig":
            translationKey = "correctionMarks.categories.content.correct";
            break;
          case "inhaltlich falsch":
            translationKey = "correctionMarks.categories.content.wrong";
            break;
          case "inhaltlich ungenau":
            translationKey = "correctionMarks.categories.content.imprecise";
            break;
          case "inhaltlich unlogisch":
            translationKey = "correctionMarks.categories.content.illogical";
            break;
          case "inhaltlich unvollständig":
            translationKey = "correctionMarks.categories.content.incomplete";
            break;
          case "inhaltliche Wiederholung":
            translationKey = "correctionMarks.categories.content.repetition";
            break;
          case "inhaltlich irrelevant":
            translationKey = "correctionMarks.categories.content.irrelevant";
            break;
          default:
            return <Text>{text}</Text>;
        }
        return <Text>{t(translationKey)}</Text>;
      },
    },
    showFrequencies
      ? {
          title: t("correctionMarks.columns.count"),
          dataIndex: "count",
          width: 50,
          render: (text, record, index) => (
            <Text>
              {
                annotations.filter((annotation) => {
                  const types = annotation.type.split("|");
                  return types.some((type) => type === record.abbreviation);
                }).length
              }
            </Text>
          ),
        }
      : null,
  ];

  const columnsContent = [
    {
      title: t("correctionMarks.columns.mark"),
      dataIndex: "customAbbreviation",
      width: 100,
      render: (text, record, index) => (
        <Popover
          content={
            editingContentIndex === index ? (
              <Space direction="vertical" align="center">
                <Space direction="horizontal">
                  <ColorPicker
                    value={record.customColor}
                    onChange={(color) =>
                      handleColorChange(color, index, record.annotationCategory)
                    }
                  />
                  <Button
                    onClick={() => handleSave(index, "content")}
                    style={{ marginTop: 8 }}
                    icon={<SaveOutlined />}
                  />
                  <Button
                    onClick={() => handleCancel("content")}
                    style={{ marginTop: 8 }}
                    icon={<CloseCircleOutlined />}
                  />
                </Space>
              </Space>
            ) : null
          }
          trigger="click"
          open={editingContentIndex === index}
          onVisibleChange={(visible) =>
            handlePopoverVisibleChange(visible, "content")
          }
        >
          <Text
            style={{
              color: record.customColor,
              fontSize: "1.2em",
              fontFamily: "Times New Roman",
              cursor: "pointer",
            }}
            onClick={() => handleEdit(index, "content")}
          >
            {"I1"}
          </Text>
        </Popover>
      ),
    },
    {
      title: t("correctionMarks.columns.meaning"),
      dataIndex: "type",
      render: (text, record) => {
        let translationKey;
        switch (text) {
          case "inhaltlich richtig":
            translationKey = "correctionMarks.categories.content.correct";
            break;
          case "inhaltlich falsch":
            translationKey = "correctionMarks.categories.content.wrong";
            break;
          case "inhaltlich ungenau":
            translationKey = "correctionMarks.categories.content.imprecise";
            break;
          case "inhaltlich unlogisch":
            translationKey = "correctionMarks.categories.content.illogical";
            break;
          case "inhaltlich unvollständig":
            translationKey = "correctionMarks.categories.content.incomplete";
            break;
          case "inhaltliche Wiederholung":
            translationKey = "correctionMarks.categories.content.repetition";
            break;
          case "inhaltlich irrelevant":
            translationKey = "correctionMarks.categories.content.irrelevant";
            break;
          default:
            return <Text>{text}</Text>;
        }
        return <Text>{t(translationKey)}</Text>;
      },
    },
    showFrequencies
      ? {
          title: t("correctionMarks.columns.count"),
          dataIndex: "count",
          width: 50,
          render: (text, record, index) => (
            <Text>
              {
                annotations.filter((annotation) =>
                  annotation.type.includes(record.type)
                ).length
              }
            </Text>
          ),
        }
      : null,
  ];

  return (
    <>
      <Title level={5}>{t("correctionMarks.titles.language")}</Title>
      <Table
        showHeader={false}
        size="small"
        columns={columnsLanguage.filter(Boolean)}
        dataSource={correctionMarks?.filter(
          (mark) =>
            mark.annotationCategory === "linguistic" && !mark.type.includes("H")
        )}
        rowKey={(record, index) => filteredIndices[index]}
        pagination={false}
      />
      <Title level={5}>{t("correctionMarks.titles.content")}</Title>
      <Table
        showHeader={false}
        size="small"
        columns={columnsContent.filter(Boolean)}
        dataSource={correctionMarks?.filter(
          (mark) => mark.annotationCategory === "content"
        )}
        rowKey={(record, index) => index}
        pagination={false}
      />
    </>
  );
};

export { CorrectionMarkDefinition, defaultCorrectionMarks };
