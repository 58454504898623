import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import { useParams, useNavigate } from "react-router-dom";

import { useAuth } from "../App";

import storage from "../utils/storage";
import db from "../utils/db"; // Assuming you have a module to export your Firestore db instance
import { doc, setDoc, collection, query, where } from "firebase/firestore"; // Import Firestore document reference and setDoc
import { ref, uploadBytesResumable } from "firebase/storage";

import {
  useDocumentData,
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import functions from "../utils/functions";

import { httpsCallable } from "firebase/functions";
import { useSubscription } from "../hooks/useSubscription";
import SubscriptionContent from "../components/SubscriptionContent";

import {
  Modal,
  message,
  Alert,
  Button,
  Space,
  Checkbox,
  Upload,
  Progress,
  Popconfirm,
  Input,
  Tooltip,
  Radio,
  notification,
  Card,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import StepsComponent from "../components/StepsComponent";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFUploadPage = () => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(null);

  let { user } = useAuth();

  const { examIdProp } = useParams();

  const [userData, userLoading, userDataError] = useDocumentData(
    doc(db, `users`, user.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [
    subscriptionCollection,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionLoading,
    // eslint-disable-next-line no-unused-vars
    subscriptionCollectionError,
  ] = useCollection(
    query(collection(db, `customers/${user.uid}/subscriptions`)),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [activeSubscriptionName, setActiveSubscriptionName] = useState(null);

  const hasActiveSubscription =
    subscriptionCollection &&
    subscriptionCollection.docs.length > 0 &&
    subscriptionCollection.docs.some((doc) => doc.data().status === "active");

  useEffect(() => {
    if (subscriptionCollection) {
      const activeSubscription = subscriptionCollection.docs.find(
        (doc) => doc.data().status === "active"
      );

      if (activeSubscription) {
        const subscriptionData = activeSubscription.data();
        // Get product name from items array
        const productName =
          subscriptionData.items?.[0]?.price?.product?.name ||
          subscriptionData.items?.[0]?.plan?.product?.name;
        setActiveSubscriptionName(productName);
        console.log(productName);
      }
    }
  }, [subscriptionCollection]);

  const uploadToStorage = async (options) => {
    const { file, onSuccess, onError, onProgress } = options;

    setIsUploading(true); // Set loading state to true

    // After successful upload, set isUploaded to true for the exam
    const examDocRef = doc(db, `users/${user.uid}/exams`, examIdProp);
    await setDoc(
      examDocRef,
      {
        selectedPages: selectedPages,
      },
      { merge: true }
    );

    try {
      // Create a reference to the location where you want to store the file
      const storageRef = ref(
        storage,
        `users/${user.uid}/exams/${examIdProp}/submissions/upload.pdf`
      );

      // Start the upload task
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress); // Update the upload progress state
          onProgress({ percent: progress });
        },
        (error) => {
          setIsUploading(false);
          setUploadProgress(null); // Reset the upload progress state on error
          onError(error);
        },
        async () => {
          try {
            // Update exam document
            const examDocRef = doc(db, `users/${user.uid}/exams`, examIdProp);
            await setDoc(
              examDocRef,
              {
                isUploaded: true,
                selectedPages: selectedPages,
                processing: true,
              },
              { merge: true }
            );

            if (activeSubscriptionName === "zengrade Pay as you go") {
              // Calculate pages that need to be paid for
              const permanentCreditsToUse = Math.min(
                userData?.permanentCredits || 0,
                numPages
              );
              const paidPages = Math.max(0, numPages - permanentCreditsToUse);

              // Update permanent credits in user document
              const userDocRef = doc(db, `users`, user.uid);
              const newPermanentCredits = Math.max(
                0,
                (userData?.permanentCredits || 0) - permanentCreditsToUse
              );

              await setDoc(
                userDocRef,
                {
                  permanentCredits: newPermanentCredits,
                },
                { merge: true }
              );

              // Only call reportStripeUsage if there are pages to be paid for
              if (paidPages > 0) {
                const reportUsage = httpsCallable(
                  functions,
                  "reportStripeUsage"
                );
                console.log("Reporting usage for paid pages:", paidPages);
                await reportUsage({
                  pages: paidPages,
                  userId: user.uid,
                });
              }

              notification.success({
                message: t("upload.pdfUpload.uploadSuccess"),
                description: t("upload.pdfUpload.processing.pages", {
                  count: numPages,
                }),
                duration: 15,
                placement: "bottom",
              });
            } else {
              // Existing credit deduction logic for other subscription types
              const userDocRef = doc(db, `users`, user.uid);
              const subscriptionCredits = userData?.subscriptionCredits || 0;
              const permanentCredits = userData?.permanentCredits || 0;

              let newSubscriptionCredits = subscriptionCredits;
              let newPermanentCredits = permanentCredits;
              let remainingPages = numPages;

              if (subscriptionCredits >= numPages) {
                newSubscriptionCredits -= numPages;
              } else {
                newSubscriptionCredits = 0;
                remainingPages -= subscriptionCredits;
                newPermanentCredits = Math.max(
                  0,
                  permanentCredits - remainingPages
                );
              }

              await setDoc(
                userDocRef,
                {
                  subscriptionCredits: newSubscriptionCredits,
                  permanentCredits: newPermanentCredits,
                },
                { merge: true }
              );

              notification.success({
                message: t("upload.pdfUpload.uploadSuccess"),
                description: t("upload.pdfUpload.processing.sufficient"),
                duration: 15,
                placement: "bottom",
              });
            }

            setIsUploading(false);
            setUploadProgress(null);
            onSuccess(null, file);
          } catch (error) {
            console.error("Error in upload completion:", error);
            notification.error({
              message: t("upload.pdfUpload.uploadError"),
              description: t("upload.pdfUpload.tryAgain"),
              duration: 0,
            });
            onError(error);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false);
      onError(error);
    }
  };

  const [numPages, setNumPages] = useState(null);
  const [selectedPages, setSelectedPages] = useState([1]);
  const [file, setFile] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({
    scanOptions: {
      scanQuality: true,
      dpi300: true,
      formatA4: true,
      colorScan: true,
      order: true,
      rotation: true,
    },
    documentFeatures: {
      blue: true,
      handwritten: true,
      singleTask: true,
      lineatur: true,
    },
  });

  const onCheckboxChange = (group, e) => {
    if (e.target.name === "confirmTexts") {
      setConfirmTexts(e.target.checked);
    }
  };

  const allChecked =
    Object.values(checkboxValues.scanOptions).every(
      (value) => value === true
    ) &&
    Object.values(checkboxValues.documentFeatures).every(
      (value) => value === true
    );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // function togglePageSelection(pageNum) {
  //   setSelectedPages((prevSelectedPages) => {
  //     if (prevSelectedPages.includes(pageNum)) {
  //       return prevSelectedPages.filter((page) => page !== pageNum);
  //     } else {
  //       return [...prevSelectedPages, pageNum];
  //     }
  //   });
  // }

  function togglePageSelection(pageNum) {
    if (pageNum === 1) return; // Prevent deselecting the first page

    setSelectedPages((prevSelectedPages) => {
      if (prevSelectedPages.includes(pageNum)) {
        return prevSelectedPages.filter((page) => page !== pageNum);
      } else {
        return [...prevSelectedPages, pageNum].sort((a, b) => a - b);
      }
    });
  }

  function getStudentTextInfo(pageNum) {
    if (selectedPages.length === 1) return null; // No info if only the first page is selected

    for (let i = 0; i < selectedPages.length; i++) {
      if (pageNum < selectedPages[i]) {
        const studentTextNumber = i;
        const pageInStudentText = pageNum - selectedPages[i - 1] + 1;
        return { studentTextNumber, pageInStudentText };
      }
    }

    const studentTextNumber = selectedPages.length;
    const pageInStudentText =
      pageNum - selectedPages[selectedPages.length - 1] + 1;
    return { studentTextNumber, pageInStudentText };
  }

  const getStudentTextRanges = () => {
    if (numPages === 1) {
      return [
        `${t("upload.pdfUpload.essay")} 1: ${t("upload.pdfUpload.page")} 1`,
      ];
    }

    if (selectedPages.length === 1) {
      return [
        `${t("upload.pdfUpload.essay")} 1: ${t(
          "upload.pdfUpload.page"
        )} 1 - ${numPages}`,
      ];
    }

    const ranges = [];
    for (let i = 0; i < selectedPages.length; i++) {
      const start = selectedPages[i];
      const end = selectedPages[i + 1] ? selectedPages[i + 1] - 1 : numPages;
      if (start === end) {
        ranges.push(
          `${t("upload.pdfUpload.essay")} ${i + 1}: ${t(
            "upload.pdfUpload.page"
          )} ${start}`
        );
      } else {
        ranges.push(
          `${t("upload.pdfUpload.essay")} ${i + 1}: ${t(
            "upload.pdfUpload.page"
          )} ${start} - ${end}`
        );
      }
    }
    return ranges;
  };

  const [confirmTexts, setConfirmTexts] = useState(false);

  const hasAufsatzOverLimit = () => {
    const pageLimit = 22;

    if (numPages <= pageLimit) return false;

    if (selectedPages.length === 1) return true;

    for (let i = 0; i < selectedPages.length; i++) {
      const start = selectedPages[i];
      const end = selectedPages[i + 1] ? selectedPages[i + 1] - 1 : numPages;
      if (end - start + 1 > pageLimit) return true;
    }
    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
    // Setzen Sie den Upload-Fortschritt zurück, wenn das Modal geöffnet wird
    setUploadProgress(null);
  };

  const handlePdfUpload = async () => {
    if (!file) {
      message.error(t("upload.pdfUpload.errors.selectPDF"));
      return;
    }

    const uploadOptions = {
      file: file,
      onSuccess: () => {
        navigate(`/status/${examIdProp}`);
      },
      onError: (error) =>
        message.error(
          t("upload.pdfUpload.errors.uploadError", { message: error.message })
        ),
      onProgress: (progress) =>
        console.log(`Upload progress: ${progress.percent}%`),
    };

    await uploadToStorage(uploadOptions);
  };

  const handleOk = () => {
    // setIsModalVisible(false);
    handlePdfUpload(); // Startet den Upload-Prozess, wenn der Benutzer auf "PDF Upload starten" klickt
    console.log("PDF Upload gestartet");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyUrl = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      message.success(t("upload.pdfUpload.errors.copySuccess"), 5);
      handleCancel();
    } catch (err) {
      message.error(t("upload.pdfUpload.errors.copyError"), 5);
    }
  };

  const totalCredits =
    (userData?.permanentCredits || 0) + (userData?.subscriptionCredits || 0);
  const hasEnoughCredits =
    activeSubscriptionName === "zengrade Pay as you go"
      ? true
      : totalCredits >= numPages;
  const permanentCreditsToUse = Math.min(
    userData?.permanentCredits || 0,
    numPages || 0
  );
  const payAsYouGoPages = Math.max(0, (numPages || 0) - permanentCreditsToUse);
  const PRICE_PER_PAGE = 0.25; // 25 Cent pro Seite

  const {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  } = useSubscription(user);

  const [showPaymentOptions, setShowPaymentOptions] = useState(false);

  if ((totalCredits === 0 && !hasActiveSubscription) || showPaymentOptions) {
    return (
      <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
        <p>{t("upload.pdfUpload.outOfCredits.message")}</p>
        <SubscriptionContent onSubscribe={handleSubscribe} />
      </div>
    );
  }

  const customItemRender = (originNode, file, fileList) => {
    return (
      <center>
        <small>{file.name}</small>
      </center>
    );
  };

  const isDisabled =
    totalCredits === 0 && activeSubscriptionName !== "zengrade Pay as you go";

  const uploadButton = (
    <Button disabled={isDisabled} icon={<UploadOutlined />}>
      {t("upload.pdfUpload.selectPDF")}
    </Button>
  );

  return (
    <>
      <StepsComponent step={1} />

      <div style={{ marginBottom: "100px" }}>
        <Modal
          title={t("upload.pdfUpload.title")}
          open={isModalVisible}
          maskClosable={false}
          closable={false}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Popconfirm
              title={
                <>
                  <div style={{ width: "300px" }}>
                    {t("upload.pdfUpload.selectPages")}
                  </div>
                  <br />
                  <Input
                    size="small"
                    width={"100%"}
                    value={`https://app.zengrade.com/upload/${examIdProp}`}
                    readOnly
                  />
                </>
              }
              onConfirm={() =>
                handleCopyUrl(`https://app.zengrade.com/upload/${examIdProp}`)
              }
              onCancel={handleCancel}
              okText={t("upload.pdfUpload.copy")}
              cancelText={t("upload.pdfUpload.continueWithCancel")}
            >
              <Button>{t("upload.pdfUpload.cancel")}</Button>
            </Popconfirm>,
            <Button
              key="submit"
              type="primary"
              disabled={!allChecked || isUploading || !confirmTexts}
              onClick={handleOk}
            >
              {t("upload.pdfUpload.startUpload")}
            </Button>,
          ]}
          bodyStyle={{ maxHeight: "60vh", overflowY: "auto" }}
        >
          <small>{t("upload.requirements.scanRecommendation")}</small>
          <div style={{ marginTop: "20px" }}>
            <p>
              <b>{t("upload.pdfUpload.essayDivision")}</b>
            </p>
            <ul>
              {getStudentTextRanges().map((range, index) => (
                <li key={index}>{range}</li>
              ))}
            </ul>
            {hasAufsatzOverLimit() && (
              <Alert
                message={t("upload.pdfUpload.maxPagesWarning")}
                type="error"
                showIcon
                style={{ marginBottom: "10px" }}
              />
            )}
            <Checkbox
              name="confirmTexts"
              onChange={(e) => setConfirmTexts(e.target.checked)}
              disabled={hasAufsatzOverLimit()}
            >
              {t("upload.pdfUpload.divisionCorrect")}
            </Checkbox>
          </div>
          <div>
            <p>
              <b>{t("upload.pdfUpload.optimalScan")}</b>
            </p>
            <Checkbox name="scanQuality" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.multifunction")}
            </Checkbox>
            <br />
            <Checkbox name="dpi300" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.resolution")}
            </Checkbox>
            <br />
            <Checkbox name="colorScan" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.color")}
            </Checkbox>
            <br />
            <Checkbox name="formatA4" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.format")}
            </Checkbox>
            <br />
            <Checkbox name="order" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.order")}
            </Checkbox>
            <br />
            <Checkbox name="rotation" checked={true} disabled={true}>
              {t("upload.pdfUpload.scanFeatures.rotation")}
            </Checkbox>
          </div>
          <br />
          <small>{t("upload.pdfUpload.scannerNote")}</small>
          <br />
          <br />
          <p>
            <b>{t("upload.pdfUpload.optimalDocument")}</b>
          </p>
          <div>
            <Checkbox name="handwritten" checked={true} disabled={true}>
              {t("upload.pdfUpload.documentFeatures.handwritten")}
            </Checkbox>
            <br />
            <Checkbox name="blue" checked={true} disabled={true}>
              {t("upload.pdfUpload.documentFeatures.blue")}
            </Checkbox>
            <br />
            <Checkbox name="lineatur" checked={true} disabled={true}>
              {t("upload.pdfUpload.documentFeatures.lineatur")}
            </Checkbox>
            <br />
            <Checkbox name="singleTask" checked={true} disabled={true}>
              {t("upload.pdfUpload.documentFeatures.singleTask")}
            </Checkbox>
            <br />
            <br />
            {isUploading && (
              <>
                <br />
                <br />
                <Progress
                  size={"small"}
                  type="circle"
                  percent={Math.round(uploadProgress)}
                  showInfo={true}
                />
              </>
            )}
          </div>
        </Modal>
        {/* Add margin to avoid overlap with the fixed area */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Card title={t("upload.pdfUpload.video.title")}>
            <iframe
              width="560"
              height="315"
              src={
                i18n.language === "en"
                  ? "https://www.youtube.com/embed/OEgTIQQwYcM?si=n6UTqR01-G3NTceY"
                  : "https://www.youtube.com/embed/iNtKbPNY4g4?si=OacMb74bGrM7pDhA"
              }
              title={t("upload.pdfUpload.video.title")}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </Card>
          {/* {!file && (
            <Alert
              message={`Bitte wählen Sie ein PDF aus, um fortzufahren.`}
              type="info"
              showIcon
              style={{ marginBottom: "20px" }}
            />
          )} */}
          {!file && (
            <>
              <Alert
                message={
                  activeSubscriptionName === "zengrade Pay as you go"
                    ? userData?.permanentCredits > 0
                      ? t(
                          "upload.pdfUpload.availablePages.payAsYouGo.withFree",
                          { free: userData.permanentCredits }
                        )
                      : t("upload.pdfUpload.availablePages.payAsYouGo.noFree")
                    : t("upload.pdfUpload.availablePages.subscription.info", {
                        credits: totalCredits,
                      })
                }
                type="info"
                showIcon
                style={{ marginBottom: "20px" }}
              />
              <Alert
                message={t("upload.pdfUpload.alerts.selectPDF")}
                type="info"
                showIcon
                style={{ marginBottom: "20px" }}
              />
              <Alert
                message={t("upload.pdfUpload.alerts.checkBeforeUpload")}
                type="warning"
                style={{ marginBottom: "20px" }}
                showIcon
              />
            </>
          )}

          {file && (
            <>
              <Alert
                message={(() => {
                  if (activeSubscriptionName === "zengrade Pay as you go") {
                    const totalCost = (
                      payAsYouGoPages * PRICE_PER_PAGE
                    ).toFixed(2);
                    return (
                      <>
                        {t("upload.pdfUpload.processing.pages", {
                          count: numPages,
                        })}
                        {permanentCreditsToUse > 0 && (
                          <div>
                            {t("upload.pdfUpload.processing.freePages", {
                              count: permanentCreditsToUse,
                            })}
                          </div>
                        )}
                        {payAsYouGoPages > 0 && (
                          <>
                            <div>
                              {t("upload.pdfUpload.processing.paidPages", {
                                count: payAsYouGoPages,
                              })}
                            </div>
                            <div>
                              {t("upload.pdfUpload.processing.totalCost", {
                                amount: totalCost,
                              })}
                            </div>
                          </>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {t("upload.pdfUpload.processing.pages", {
                          count: numPages,
                        })}{" "}
                        {hasEnoughCredits ? (
                          t("upload.pdfUpload.processing.sufficient")
                        ) : (
                          <span>
                            {t("upload.pdfUpload.processing.insufficient")}{" "}
                            <Button
                              type="link"
                              onClick={() => setShowPaymentOptions(true)}
                              style={{ padding: 0 }}
                            >
                              {t(
                                "upload.pdfUpload.processing.switchToPayAsYouGo"
                              )}
                            </Button>
                          </span>
                        )}
                      </>
                    );
                  }
                })()}
                type={(() => {
                  if (activeSubscriptionName === "zengrade Pay as you go") {
                    return "info";
                  }
                  return totalCredits >= numPages ? "info" : "error";
                })()}
                style={{ marginBottom: "20px" }}
                showIcon
              />
            </>
          )}

          <Upload
            accept="application/pdf"
            beforeUpload={(file) => {
              setSelectedPages([1]);
              setFile(file);
              return false;
            }}
            maxCount={1}
            itemRender={customItemRender}
          >
            {isDisabled ? (
              <Tooltip title={t("upload.pdfUpload.noCredits")}>
                {uploadButton}
              </Tooltip>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        {file && (
          <>
            <div style={{ width: "50%", margin: "0 auto" }}>
              <Alert
                message={t("upload.pdfUpload.selectPages")}
                type="warning"
              />
            </div>

            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              error={t("upload.pdfUpload.uploadError")}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                {Array.from(new Array(numPages), (el, index) => {
                  const pageNum = index + 1;
                  const studentTextInfo = getStudentTextInfo(pageNum);
                  return (
                    <div
                      key={`page_${pageNum}`}
                      style={{ margin: "10px", textAlign: "center" }}
                    >
                      <p>
                        {t("upload.pdfUpload.pageInfo.page")} {pageNum}
                        {studentTextInfo && (
                          <span style={{ color: "red" }}>
                            {" "}
                            {t("upload.pdfUpload.pageInfo.pageInEssay", {
                              essayNumber: studentTextInfo.studentTextNumber,
                              pageNumber: studentTextInfo.pageInStudentText,
                            })}
                          </span>
                        )}
                      </p>

                      <div
                        onClick={() => togglePageSelection(pageNum)}
                        style={{
                          height: "600px",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          overflow: "hidden",
                          border: selectedPages.includes(pageNum)
                            ? "5px solid blue"
                            : "none",
                        }}
                      >
                        <Page pageNumber={pageNum} height={600} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </Document>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "#f0f0f0",
                padding: "10px",
                textAlign: "center",
                borderTop: "1px solid #ddd",
              }}
            >
              <Space>
                <p>
                  <strong>{t("upload.pdfUpload.selectedPages")}</strong>{" "}
                  {selectedPages.length === 0 ? (
                    " - "
                  ) : (
                    <strong>{selectedPages.join(", ")}</strong>
                  )}
                </p>
                {!hasEnoughCredits ? (
                  <Tooltip
                    title={t("upload.pdfUpload.noCredits")}
                    placement="top"
                  >
                    <Button onClick={showModal} disabled>
                      {t("upload.pdfUpload.finishSelection")}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button onClick={showModal} type="primary">
                    {t("upload.pdfUpload.finishSelection")}
                  </Button>
                )}
              </Space>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PDFUploadPage;
