import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, setDoc, deleteDoc } from "firebase/firestore";
import db from "../utils/db";

import { useAuth } from "../App";

import { useDocumentData } from "react-firebase-hooks/firestore";

import "./CreateExamPage.css";
import { useTranslation } from "react-i18next";

const EditExamPage = () => {
  let { user } = useAuth();
  const { i18n } = useTranslation();

  const { examIdProp } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${user.uid}/exams`, examIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  return (
    <React.Fragment>
      {examData && !examDataLoading && examData?.formData?.submission_id && (
        <iframe
          id="JotFormIFrame-242972842370360"
          title="Erwartungshorizont bearbeiten"
          onload="window.parent.scrollTo(0,0)"
          src={`https://eu.jotform.com/edit/${
            examData?.formData?.submission_id
          }?language=${i18n.language || "de"}`}
          frameborder="0"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            border: "none",
            overflow: "hidden",
          }}
        ></iframe>
      )}
    </React.Fragment>
  );
};

export default EditExamPage;
