import React, { useRef, useState, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "antd";

import { useDocumentData, useCollection } from "react-firebase-hooks/firestore";

import db from "../utils/db";

import { collection, query, where, doc, onSnapshot } from "firebase/firestore";

import { useAuth } from "../App";

const GradeOverviewComponent = () => {
  let { user } = useAuth();
  let { examIdProp } = useParams();

  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${user.uid}/exams`, examIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [submissionCollection, submissionLoading, submissionError] =
    useCollection(
      query(
        collection(db, `users/${user.uid}/exams/${examIdProp}/submissions`),
        where("processed", "==", true)
      ),
      {
        snapshotListenOptions: { includeMetadataChanges: false },
      }
    );
  const [criteria, setCriteria] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, `users/${user.uid}/exams/${examIdProp}/criteria`)),
      {
        includeMetadataChanges: false,
      },
      (querySnapshot) => {
        const criteriaList = [];
        querySnapshot.forEach((doc) => {
          criteriaList.push({ id: doc.id, ...doc.data() });
        });
        setCriteria(criteriaList);
      },
      (error) => {
        console.err("Error getting documents: ", error);
      }
    );

    return () => unsubscribe();
  }, [db, user.uid, examIdProp]);

  // Berechnung der Sprach- und Inhaltsbewertungen sowie der Gesamtnote
  const calculateGrades = useMemo(() => {
    const grades = {};
    submissionCollection &&
      submissionCollection.docs.forEach((submission) => {
        const submissionData = submission.data();

        const submissionId = submission.id;

        // TODO: FILTER CRITERIA BY TASKNUM AND TASKTYPE (once displayed) ?!
        const linguisticCriteria = criteria.filter(
          (criterion) => criterion.type === "linguistic"
        );

        const hasCategories = true; // TODO: retrieve from firestore!
        let languageGrade;
        let averagePoints;
        let categoryAverages;
        // // SPRACHLICHE BEWERTUNGSKRITERIEN - alle kriterien gleich gewichtet
        if (!hasCategories) {
          let totalPoints = 0;
          linguisticCriteria.forEach((criterion) => {
            const criterionId = criterion.id;
            // Ensure that points are a number and default to 0 if not
            const points =
              submissionData.ratings &&
              !isNaN(submissionData.ratings[criterionId])
                ? submissionData.ratings[criterionId]
                : 0;
            // Ensure that criterion.points is a number and default to 0 if not
            totalPoints += points;
          });
          averagePoints = (totalPoints / linguisticCriteria.length).toFixed(0);
          languageGrade = averagePoints;
        } else {
          // SPRACHLICHE BEWERTUNGSKRITERIEN - alle kriterien gleich gewichtet

          // Gruppieren der Kriterien nach Unterkategorien
          const categories = linguisticCriteria.reduce((acc, criterion) => {
            const category = criterion.description.split(": ")[0]; // Extrahieren der Unterkategorie
            if (!acc[category]) {
              acc[category] = [];
            }
            acc[category].push(criterion);
            return acc;
          }, {});

          // Berechnen der durchschnittlichen Punkte für jede Unterkategorie
          categoryAverages = Object.keys(categories).map((category) => {
            const criteria = categories[category];
            let totalPoints = 0;
            criteria.forEach((criterion) => {
              const criterionId = criterion.id;
              const points =
                submissionData.ratings &&
                !isNaN(submissionData.ratings[criterionId])
                  ? submissionData.ratings[criterionId]
                  : 0;
              totalPoints += points;
            });
            const averagePoints = (totalPoints / criteria.length).toFixed(0);
            return {
              category,
              averagePoints,
            };
          });

          averagePoints =
            categoryAverages.reduce(
              (acc, c) => acc + parseInt(c.averagePoints),
              0
            ) / categoryAverages.length;
          averagePoints = averagePoints.toFixed(0);

          languageGrade = averagePoints;
        }
        // CONTENT CRITERIA
        const contentCriteria = criteria.filter(
          (criterion) => criterion.type === "content"
        );

        // // SPRACHLICHE BEWERTUNGSKRITERIEN - alle kriterien gleich gewichtet
        let totalPoints = 0;
        contentCriteria.forEach((criterion) => {
          const criterionId = criterion.id;
          // Ensure that points are a number and default to 0 if not
          const points =
            submissionData.ratings &&
            !isNaN(submissionData.ratings[criterionId])
              ? submissionData.ratings[criterionId]
              : 0;
          // Ensure that criterion.points is a number and default to 0 if not
          totalPoints += points;
        });

        const contentGrade = (totalPoints / contentCriteria.length).toFixed(0);
        const totalGrade = (
          (languageGrade * (examData?.ratio ? examData.ratio : 50) +
            contentGrade * (examData?.ratio ? 100 - examData.ratio : 50)) /
          100
        ).toFixed(0);

        grades[submissionId] = {
          languageGrade,
          contentGrade,
          totalGrade,
        };
      });
    return grades;
  }, [criteria, submissionCollection]);

  // Anpassung der dataSource für die Table-Komponente
  const dataSource = useMemo(() => {
    const gradesData = Object.entries(calculateGrades).map(
      ([submissionId, { languageGrade, contentGrade, totalGrade }]) => ({
        key: submissionId.replace("submission", "Einreichung "),
        task: "Aufgabe 1",
        languageGrade: languageGrade,
        contentGrade: contentGrade,
        totalGrade: totalGrade,
      })
    );

    return gradesData;
  }, [calculateGrades]);

  const columns = useMemo(
    () => [
      {
        title: "Einreichung",
        dataIndex: "key",
        key: "key",
      },
      {
        title: "Aufgabe",
        dataIndex: "task",
        key: "task",
      },
      {
        title: `Note Sprache (${examData?.ratio ? examData.ratio : 50} %)`,
        dataIndex: "languageGrade",
        key: "languageGrade",
      },
      {
        title: `Note Inhalt (${examData?.ratio ? 100 - examData.ratio : 50} %)`,
        dataIndex: "contentGrade",
        key: "contentGrade",
      },
      {
        title: "Gesamtnote",
        dataIndex: "totalGrade",
        key: "totalGrade",
      },
    ],
    []
  );

  return (
    <div className="container">
      {submissionLoading || examDataLoading ? (
        <p>Loading data...</p>
      ) : submissionError || examDataError ? (
        <p>Error loading data. Please try again later.</p>
      ) : (
        <>
          <div>
            <h2>Notenübersicht</h2>
            <Table
              size="small"
              dataSource={dataSource}
              columns={columns}
              pagination={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default GradeOverviewComponent;
