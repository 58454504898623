import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Space, Button, Tooltip, Alert } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import db from "../utils/db";
import { collection } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { useAuth } from "../App";
import { useTranslation } from "react-i18next";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";
import ZengradeLogoDeactivated from "../assets/zengrade-logo-nobackground-deactivated.svg";
import "./ViewExamsPage.css";

const ViewExamsPage = () => {
  const { t, i18n } = useTranslation();
  const { user } = useAuth();
  let navigate = useNavigate();
  const location = useLocation();
  const [highlightRecent, setHighlightRecent] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("creation_success") === "true") {
      setHighlightRecent(true);
    }
  }, [location]);

  const [examCollection, examCollectionLoading, examCollectionError] =
    useCollection(collection(db, `users/${user.uid}/exams`), {
      snapshotListenOptions: { includeMetadataChanges: false },
    });

  const cutoffDate = new Date("2024-04-25");

  return (
    <div className="container">
      <Space wrap>
        {examCollectionError && (
          <Card>
            <p>
              {t("viewExams.errors.browserCache.description")}{" "}
              <a
                href="https://www.ionos.de/digitalguide/server/konfiguration/so-leeren-sie-ihren-cache/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("viewExams.errors.browserCache.here")}
              </a>
              . {t("viewExams.errors.browserCache.contact")}
            </p>
          </Card>
        )}
        {examCollectionLoading && <span>{t("viewExams.loading")}</span>}
        {examCollection && examCollection.docs.length > 0 && (
          <React.Fragment>
            {examCollection.docs
              .filter((doc) => doc.data().hasOwnProperty("name"))
              .sort((a, b) => b.data().createdAt - a.data().createdAt)
              .map((doc, index) => {
                const examData = doc.data();
                const examDateCreatedAt = examData?.createdAt
                  ? examData?.createdAt.toDate()
                  : null;
                const examDateCreateTime = examData?.createTime
                  ? examData?.createTime.toDate()
                  : null;

                let isOldExam = examData?.createdAt
                  ? examDateCreatedAt && examDateCreatedAt < cutoffDate
                  : false;

                isOldExam =
                  examData?.createTime && !examData?.createdAt
                    ? examDateCreateTime < cutoffDate
                    : isOldExam;

                isOldExam =
                  !examData?.createTime &&
                  !examData?.createdAt &&
                  !examData?.formData
                    ? true
                    : isOldExam;

                return (
                  <Card
                    style={{
                      margin: 16,
                      width: 300,
                      ...(highlightRecent && index === 0
                        ? {
                            boxShadow: "0 0 10px #1890ff",
                            transition: "box-shadow 0.3s ease-in-out",
                          }
                        : {}),
                    }}
                    size="medium"
                    key={doc.id}
                  >
                    <Space direction="vertical">
                      <Space direction="horizontal">
                        {examData.isUploaded && (
                          <Tooltip
                            title={
                              examData?.isReady
                                ? t("viewExams.tooltips.correctionAvailable")
                                : examData?.isUploaded
                                ? t("viewExams.tooltips.correctionInProgress")
                                : t("viewExams.tooltips.pdfNotUploaded")
                            }
                          >
                            <img
                              src={
                                examData?.isReady
                                  ? ZengradeLogo
                                  : ZengradeLogoDeactivated
                              }
                              alt="logo"
                              style={{ width: "20px", height: "20px" }}
                            />
                          </Tooltip>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {isOldExam ? (
                            <Tooltip title={t("viewExams.tooltips.oldTask")}>
                              <span
                                style={{
                                  flex: 1,
                                  marginRight: "10px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  color: "gray",
                                  cursor: "not-allowed",
                                }}
                              >
                                {examData.name}
                              </span>
                            </Tooltip>
                          ) : (
                            <>
                              <Tooltip
                                title={
                                  examData.name.length > 15 ? examData.name : ""
                                }
                              >
                                <Link
                                  to={`/status/${doc.id}`}
                                  style={{
                                    flex: 1,
                                    marginRight: "10px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {examData.name.length > 15
                                    ? `${examData.name.substring(0, 15)}...`
                                    : examData.name}
                                </Link>
                              </Tooltip>
                              {!examData.isUploaded && (
                                <>
                                  <Tooltip
                                    title={t("viewExams.tooltips.uploadPDF")}
                                  >
                                    <Button
                                      onClick={() =>
                                        navigate(`/upload/${doc.id}`)
                                      }
                                      type="secondary"
                                      shape="circle"
                                      icon={<UploadOutlined />}
                                    />
                                  </Tooltip>
                                  {examData.formData?.submission_id && (
                                    <Tooltip
                                      title={t(
                                        "viewExams.tooltips.editExpectation"
                                      )}
                                    >
                                      <Button
                                        onClick={() =>
                                          navigate(`/edit/${doc.id}`)
                                        }
                                        type="secondary"
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        style={{ flexShrink: 0 }}
                                      />
                                    </Tooltip>
                                  )}
                                  <Tooltip
                                    title={t(
                                      "viewExams.tooltips.deleteExpectation"
                                    )}
                                  >
                                    <Button
                                      onClick={() =>
                                        navigate(`/delete/${doc.id}`)
                                      }
                                      type="secondary"
                                      shape="circle"
                                      icon={<DeleteOutlined />}
                                      style={{ flexShrink: 0 }}
                                    />
                                  </Tooltip>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Space>
                      <small>
                        {examData.createdAt
                          .toDate()
                          .toLocaleDateString(
                            i18n.language === "en" ? "en-US" : "de-DE"
                          )}
                      </small>
                    </Space>
                  </Card>
                );
              })}
          </React.Fragment>
        )}
      </Space>
      <Alert
        message={
          <div>
            <h3>{t("viewExams.welcome.title")}</h3>
            <h4>{t("viewExams.welcome.technicalNotes.title")}</h4>
            <ul>
              <li>
                <strong>
                  {t("viewExams.welcome.technicalNotes.browser.title")}
                </strong>{" "}
                {t("viewExams.welcome.technicalNotes.browser.description")}
              </li>
            </ul>
            <h4>{t("viewExams.welcome.adaptationPeriod.title")}</h4>
            <ul>
              <li>
                {t("viewExams.welcome.adaptationPeriod.description")}{" "}
                <strong>
                  {t("viewExams.welcome.adaptationPeriod.recommendation")}
                </strong>
              </li>
            </ul>
            <h4>{t("viewExams.welcome.writingSheets.title")}</h4>
            <ul>
              <li>{t("viewExams.welcome.writingSheets.conditions")}</li>
              <li>{t("viewExams.welcome.writingSheets.recommendations")}</li>
              <li>
                {t("viewExams.welcome.writingSheets.studentNames")}{" "}
                <a
                  href={
                    i18n.language === "en"
                      ? "https://www.youtube.com/watch?v=uW4Us1MG4aQ&list=PLExbu5e54Sckckd2uceNC1IJGP4xF9JMf&index=2&t=1s"
                      : "https://www.youtube.com/watch?v=y9XTEAN-okQ&list=PLExbu5e54Scmw82BcXLsW9pFbP3cj6uqH&index=1&t=1s"
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("viewExams.welcome.links.tutorial")}
                </a>
              </li>
            </ul>
            <p>
              {t("viewExams.welcome.links.tutorials")}{" "}
              <a
                href={
                  i18n.language === "en"
                    ? "https://www.youtube.com/watch?v=uW4Us1MG4aQ&list=PLExbu5e54Sckckd2uceNC1IJGP4xF9JMf&index=2&t=1s"
                    : "https://www.youtube.com/watch?v=y9XTEAN-okQ&list=PLExbu5e54Scmw82BcXLsW9pFbP3cj6uqH&index=1&t=1s"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("viewExams.welcome.links.tutorials")}
              </a>
            </p>
            <p>
              <a
                href="https://zengrade.notion.site/Nutzungsbedingungen-1d0dd627d65e423e90b14fb27a3ea75a"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("viewExams.welcome.links.termsOfUse")}
              </a>
            </p>
            <p>
              {t("viewExams.welcome.support.questions")}{" "}
              <a href="mailto:info@zengrade.com">info@zengrade.com</a>.{" "}
              {t("viewExams.welcome.support.closing")}
            </p>
          </div>
        }
        type="warning"
        style={{ width: "50%" }}
      />
    </div>
  );
};

export default ViewExamsPage;
