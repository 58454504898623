import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, Row, Col, List } from "antd";
import TypeImage from "../assets/screenshot_typed.jpg";
import ScanImage from "../assets/screenshot_scan.jpg";
import PencilImage from "../assets/screenshot_pencil.jpg";

const UploadChoice = () => {
  const navigate = useNavigate();
  const { examIdProp } = useParams();

  const handleChoice = (path) => {
    navigate(path);
  };

  const pointsTyped = [
    "Upload von mehreren Dateien im .docx-Format",
    "sofortige Verarbeitung",
    "geeignet für Hausaufgaben, Facharbeiten",
  ];

  const pointsPencil = [
    "Upload von mehreren Dateien im .pdf-Format",
    "sofortige Verarbeitung",
    "geeignet für Hausaufgaben, im Unterricht geschriebene Texte, Tests der Handschrifterkennung",
  ];

  const pointsScan = [
    "Upload eines Scans in einer einzigen PDF-Datei mit allen Texten",
    "Verarbeitung innerhalb 24 Stunden, meist schneller",
    "geeignet für Klausuren",
  ];

  return (
    <Row gutter={16} style={{ padding: "20px" }}>
      <Col span={8}>
        <Card
          hoverable
          onClick={() =>
            handleChoice(`/upload-text/${examIdProp}?textType="typed"`)
          }
          title="Getippter Text"
          cover={<img alt="Getippter Text" src={TypeImage} />}
        >
          <List
            size="small"
            dataSource={pointsTyped}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          hoverable
          onClick={() =>
            handleChoice(`/upload-text/${examIdProp}?textType="pencil"`)
          }
          title="Digitale Handschrift"
          cover={<img alt="Digitale Handschrift" src={PencilImage} />}
        >
          <List
            size="small"
            dataSource={pointsPencil}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Card>
      </Col>
      <Col span={8}>
        <Card
          hoverable
          onClick={() => handleChoice(`/upload/${examIdProp}`)}
          title="Gescannte Handschrift"
          cover={<img alt="Gescannte Handschrift" src={ScanImage} />}
        >
          <List
            size="small"
            dataSource={pointsScan}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default UploadChoice;
