// utils/common.js

// Function to extract the image name from a URL
export const extractImageName = (url) => {
  try {
    const imageUrl = new URL(url);
    let paths = imageUrl.pathname
      .split("/")
      .map((segment) => decodeURIComponent(segment));
    let imageName = paths[paths.length - 1];
    if (imageName.includes("/")) {
      paths = imageName.split("/");
      imageName = paths[paths.length - 1];
    }
    return imageName;
  } catch (error) {
    console.error("Error extracting image name:", error);
    console.log(`url that causes issues -> ${url}`);
    return null;
  }
};

// Function to extract the image details from a URL
export const extractImageDetails = (url) => {
  const imageName = extractImageName(url);
  if (imageName) {
    const parts = imageName.split("_");
    if (parts.length >= 2) {
      const submissionId = parts.slice(0, -1).join("_");
      const taskNum = parts
        .slice(-1)[0]
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      return { submissionId, taskNum };
    }
  }
  return { submissionId: null, taskNum: null };
};

// Function to extract the image details including submissionId, taskNum, annotationNum, and wordNum from a URL
export const extractImageDetailsFromDifferenceFolder = (url) => {
  const imageName = extractImageName(url);
  if (imageName) {
    const parts = imageName.split("_");
    if (parts.length >= 4) {
      const submissionId = parts[0];
      const taskNum = parts[1];
      const annotationNum = parts[2];
      const lineNum = parts[3];
      const wordNum = parts[4]
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      return { submissionId, taskNum, annotationNum, lineNum, wordNum };
    }
  }
  return {
    submissionId: null,
    taskNum: null,
    annotationNum: null,
    lineNum: null,
    wordNum: null,
  };
};

// Function to extract the image details from all URLs
export const extractAllImageDetails = (imageList) => {
  if (!Array.isArray(imageList)) {
    console.error("Expected an array for imageList, received:", imageList);
    return [];
  }

  return imageList
    .map((url) => {
      return extractImageDetails(url);
    })
    .filter((details) => details.submissionId && details.taskNum);
};
