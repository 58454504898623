import React from "react";
import {
  EditOutlined,
  CloudUploadOutlined,
  LoadingOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { Steps } from "antd";
import { useTranslation } from 'react-i18next';

const StepsComponent = ({ step }) => {
  const { t } = useTranslation();

  const stepsItems = [
    {
      title: t('steps.createExpectation'),
      icon: <FileAddOutlined />,
    },
    {
      title: t('steps.uploadAndDivide'),
      icon: <CloudUploadOutlined />,
    },
    {
      title: t('steps.reviewCorrection'),
      icon: step === 3 ? <LoadingOutlined /> : <EditOutlined />,
    },
  ];

  const updatedStepsItems = stepsItems.map((item, index) => ({
    ...item,
    status: index < step ? "finish" : index === step ? "process" : "wait",
  }));

  return <Steps direction="vertical" size="small" items={updatedStepsItems} />;
};

export default StepsComponent;
