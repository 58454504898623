import React, { useState, useEffect } from "react";
import { useAuth } from "../App";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Space } from "antd";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";

// ACHTUNG: MUSS IN APP.JS -> REQUIREAUTH AKTIVIERT SEIN, UM ZU FUNKTIONIEREN, AKTUELL DEAKTIVIERT
const EmailVerificationPage = () => {
  const { user, signout } = useAuth();
  let navigate = useNavigate();

  const handleLogoutClick = (e) => {
    e.preventDefault();
    signout(() => navigate("/login", { replace: true }));
  };
  const [emailVerified, setEmailVerified] = useState(
    user ? user.emailVerified : false
  );

  useEffect(() => {
    const checkEmailVerification = setInterval(() => {
      user.reload().then(() => {
        if (user.emailVerified) {
          setEmailVerified(true);
        }
      });
    }, 5000); // Check email verification status every 5 seconds

    return () => clearInterval(checkEmailVerification);
  }, [user]);

  const sendVerificationEmail = () => {
    user
      .sendEmailVerification()
      .then(() => {
        alert("Bestätigungs-Email gesendet!");
      })
      .catch((error) => {
        console.error("Error sending verification email:", error);
      });
  };

  if (emailVerified) {
    // return <Navigate to="/questions" replace />;
    return <Navigate to="/view" replace />;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "80vw",
        }}
      >
        <img
          src={ZengradeLogo}
          alt="logo"
          style={{ width: "200px", height: "200px" }}
        />
        <h2>Eine Bestätigungsmail wurde an {user.email} gesendet</h2>
        <p>
          Bitte überprüfen Sie Ihren Posteingang. Klicken Sie auf den Link in
          der Email, um die Nutzung von Zengrade fortzusetzen. Nach der
          Bestätigung Ihrer Email, kehren Sie zu diesem Tab oder
          app.zengrade.com zurück, um fortzufahren. Wenn Sie die Email nicht
          erhalten haben, können Sie unten eine neue Bestätigungs-Email
          anfordern.
        </p>
        <Button onClick={sendVerificationEmail}>
          Fordern Sie eine weitere Bestätigungs-Email an
        </Button>
        <br />
        <br />
        <p>
          Haben Sie Ihre Email falsch geschrieben? Bitte loggen Sie sich aus und
          versuchen Sie sich erneut anzumelden, mit der korrekten Email.
        </p>
        <Button onClick={handleLogoutClick}>Logout</Button>
      </Space>
    </div>
  );
};

export default EmailVerificationPage;
