import firebaseApp from "../utils/config";
import { getAuth } from "firebase/auth";

// Initialize Firebase
const app = firebaseApp;

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export default auth;

// import firebaseApp from "./config";
// import { getAuth, signInWithCustomToken } from "firebase/auth";

// const auth = getAuth(firebaseApp);

// export const signInToFirebase = async (getTokenFn) => {
//   try {
//     if (typeof getTokenFn !== "function") {
//       throw new Error("getTokenFn must be a function");
//     }
//     const token = await getTokenFn();
//     if (token) {
//       const userCredential = await signInWithCustomToken(auth, token);
//       return userCredential.user;
//     }
//   } catch (error) {
//     console.error("Error signing in to Firebase:", error);
//     throw error; // Re-throw the error for handling in the calling function
//   }
//   return null;
// };

// export default auth;
