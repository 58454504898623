import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import db from "../utils/db";
import { doc } from "firebase/firestore";

import StepsComponent from "../components/StepsComponent";

import { useDocumentData } from "react-firebase-hooks/firestore";

import { useAuth } from "../App";

import { Alert } from "antd";

import { useTranslation } from "react-i18next";

const StatusPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { user } = useAuth();
  const { examIdProp } = useParams();

  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${user.uid}/exams`, examIdProp)
  );

  useEffect(() => {
    // Ensure examData is loaded and there are no errors
    if (!examDataLoading && !examDataError && examData) {
      // Check if the exam is uploaded
      if (!examData.isUploaded) {
        navigate(`/upload/${examIdProp}`);
      }
      // Finally, if the exam is ready, navigate to the grading page
      else if (examData.isReady) {
        navigate(`/grade/${examIdProp}`);
      }
    }
  }, [examData, examDataLoading, examDataError, navigate, examIdProp]);

  if (
    examDataLoading ||
    examDataError ||
    !examData ||
    !examData.isUploaded ||
    !examData.selectedPages ||
    (examData.selectedPages && !examData.isReady)
  ) {
    return (
      <div className="container">
        <StepsComponent step={1} />
        <Alert
          message={
            <p style={{ fontSize: "18px" }}>
              {t("status.uploadComplete.title")}
              <br />
              <br />
              {t("status.uploadComplete.emailConfirmation")}
              <br />
              <br />
              {t("status.uploadComplete.manualCheck")}
              <br />
              <br />
              {t("status.uploadComplete.correctionProcess")}
              <br />
              <br />
              {t("status.uploadComplete.notification")}
              <br />
              <br />
              {t("status.uploadComplete.videoPrep")}
              <br />
              <iframe
                width="800"
                height="450"
                src="https://www.youtube.com/embed/jpD3jD3esKE?si=9NWpPOb6rG07KW5-"
                title={t("status.uploadComplete.videoPrep")}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </p>
          }
          type="warning"
          style={{ width: "50%" }}
        />
      </div>
    );
  }

  // This return is a fallback and should theoretically never be reached due to the navigation logic
  return null;
};

export default StatusPage;
