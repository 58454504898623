import React, { useRef, useEffect, useState } from "react";
import Phaser from "phaser";
import storage from "../utils/storage";
import { getDownloadURL, ref, listAll } from "firebase/storage";

// Keep existing constants
const SCALE_FACTOR = 0.6;
const DPI = 96;
const MM_TO_PIXEL = DPI / 25.4;

class HandwritingScene extends Phaser.Scene {
  constructor(config) {
    super({ key: "HandwritingScene" });
    this.config = config;
    this.currentZoom = 1;
    this.mistakeTypesPerLineMap = new Map();
    this.startingPosLanguageCorrectionMarks = 0;
    this.totalHeight = 0;
    this.contextMenu = null;
  }

  init() {
    this.offsets = {
      top: 150,
      left: 150,
    };

    let rightmostBoundingBox = -Infinity;
    this.config.jiix?.words
      .filter((word) => "bounding-box" in word)
      .forEach((word) => {
        const rightmostPoint =
          word["bounding-box"].x + word["bounding-box"].width;
        if (rightmostPoint > rightmostBoundingBox) {
          rightmostBoundingBox = rightmostPoint;
        }
      });

    const spaceBetweenLastWordAndLanguageCorrectionMarks = 100;
    this.startingPosLanguageCorrectionMarks =
      rightmostBoundingBox * MM_TO_PIXEL +
      this.offsets.left +
      spaceBetweenLastWordAndLanguageCorrectionMarks;
  }

  preload() {
    // Load the background image first
    if (this.config.handwritingImageUrl) {
      this.load.image("background", this.config.handwritingImageUrl);

      // Add load complete callback
      this.load.on("complete", () => {
        const image = this.textures.get("background");
        this.totalHeight = Math.max(
          image.source[0].height * SCALE_FACTOR +
            this.offsets.top * 2 * SCALE_FACTOR,
          window.innerHeight
        );
        this.scale.resize(this.scale.width, this.totalHeight);
      });
    }
  }

  create() {
    // Set initial size
    const width = window.innerWidth;
    const height = Math.max(this.totalHeight, window.innerHeight);
    this.cameras.main.setViewport(0, 0, width, height);

    // Zoom-Methode als Klassenmethode
    this.zoomCanvas = (factor) => {
      this.currentZoom *= factor;
      this.cameras.main.setZoom(this.currentZoom);
    };

    // Event-Handler für Zoom
    this.input.on("wheel", (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
      if (
        this.input.keyboard.checkModifierKey(
          this.input.keyboard.CTRL,
          "ctrlKey"
        )
      ) {
        const zoomFactor = deltaY > 0 ? 0.9 : 1.1;
        this.zoomCanvas(zoomFactor);
      }
    });

    // Touch/Click-Handler für Zoom
    this.input.on("pointerdown", (pointer) => {
      if (pointer.rightButtonDown()) {
        this.zoomCanvas(1.1);
      } else if (pointer.middleButtonDown()) {
        this.zoomCanvas(0.9);
      }
    });

    // Setze das Hintergrundbild korrekt
    if (this.config.handwritingImageUrl) {
      const bg = this.add.image(0, 0, "background");
      bg.setOrigin(0, 0);
      // Positioniere das Bild mit den korrekten Offsets
      bg.setPosition(
        this.offsets.left * SCALE_FACTOR,
        this.offsets.top * SCALE_FACTOR
      );
      bg.setScale(SCALE_FACTOR);

      // Adjust camera and world bounds based on image size
      const worldHeight = Math.max(
        bg.height * SCALE_FACTOR + this.offsets.top * 2 * SCALE_FACTOR,
        window.innerHeight
      );

      this.cameras.main.setBounds(0, 0, this.scale.width, worldHeight);

      // Set the physics world bounds if needed
      this.physics?.world?.setBounds(0, 0, this.scale.width, worldHeight);
    }

    // Add an invisible background rectangle that covers the entire canvas
    const bgRect = this.add.rectangle(
      0,
      0,
      this.scale.width,
      this.totalHeight,
      0xffffff,
      0
    );
    bgRect.setOrigin(0, 0);
    bgRect.setInteractive();
    bgRect.on("pointerdown", () => {
      if (this.contextMenu) {
        this.contextMenu.destroy(true);
        this.contextMenu = null;
      }
    });

    // Make sure it's behind everything else
    bgRect.setDepth(-1);

    this.createWordBoxes();
    this.createAnnotations();
    this.createContentAnnotations();

    // Initiale Kamera-Einstellung
    this.cameras.main.setZoom(SCALE_FACTOR); // Setzt initialen Zoom basierend auf SCALE_FACTOR

    // Optional: Kamera-Bewegung aktivieren
    this.cameras.main.setBackgroundColor("#ffffff");
    this.cameras.main.setBounds(0, 0, width * 2, height); // Großzügige Bounds

    // Update camera bounds
    this.cameras.main.setBounds(0, 0, this.scale.width, this.totalHeight);
    this.cameras.main.setBackgroundColor("#ffffff");
  }

  async loadImages() {
    const { userId, examId, submissionId } = this.config;
    let totalHeight = 0;

    const originalFolderRef = ref(
      storage,
      `users/${userId}/exams/${examId}/images_original`
    );
    const regularFolderRef = ref(
      storage,
      `users/${userId}/exams/${examId}/images`
    );

    try {
      const originalRes = await listAll(originalFolderRef);
      const taskFolderRef =
        originalRes.items.length > 0 ? originalFolderRef : regularFolderRef;

      const res = await listAll(taskFolderRef);
      const imageFiles = res.items
        .filter(
          (item) =>
            item.name.startsWith(`${submissionId}_page`) &&
            item.name.endsWith(".jpg")
        )
        .sort((a, b) => {
          const aMatch = a.name.match(/_page(\d+)\.jpg$/);
          const bMatch = b.name.match(/_page(\d+)\.jpg$/);
          return (
            (aMatch ? parseInt(aMatch[1], 10) : 0) -
            (bMatch ? parseInt(bMatch[1], 10) : 0)
          );
        });

      for (let i = 0; i < imageFiles.length; i++) {
        const url = await getDownloadURL(imageFiles[i]);
        this.load.image(`page${i}`, url);
      }

      // After loading all images, calculate total height
      this.load.on("complete", () => {
        imageFiles.forEach((_, index) => {
          const image = this.textures.get(`page${index}`);
          totalHeight += image.source[0].height * SCALE_FACTOR;
        });

        // Add padding
        totalHeight += this.offsets.top * 2 * SCALE_FACTOR;

        // Set the new height
        this.totalHeight = Math.max(totalHeight, window.innerHeight);
        this.scale.resize(this.scale.width, this.totalHeight);

        // Update camera bounds
        this.cameras.main.setBounds(0, 0, this.scale.width, this.totalHeight);

        // Update world bounds if using physics
        if (this.physics?.world) {
          this.physics.world.setBounds(
            0,
            0,
            this.scale.width,
            this.totalHeight
          );
        }
      });
    } catch (error) {
      console.error("Error loading images:", error);
    }
  }

  createWordBoxes() {
    const { jiix, onWordClick } = this.config;
    if (!jiix?.words) return;

    jiix.words.forEach((word, index) => {
      if (!word["bounding-box"]) return;

      const box = word["bounding-box"];
      const x =
        this.offsets.left * SCALE_FACTOR + box.x * MM_TO_PIXEL * SCALE_FACTOR;
      const y =
        this.offsets.top * SCALE_FACTOR + box.y * MM_TO_PIXEL * SCALE_FACTOR;
      const width = box.width * MM_TO_PIXEL * SCALE_FACTOR;
      const height = box.height * MM_TO_PIXEL * SCALE_FACTOR;

      // Light gray with high transparency (0.1 alpha)
      const rect = this.add.rectangle(x, y, width, height, 0x808080, 0.1);
      rect.setOrigin(0, 0);

      rect.setInteractive({
        hitArea: new Phaser.Geom.Rectangle(0, 0, width, height),
        hitAreaCallback: Phaser.Geom.Rectangle.Contains,
        useHandCursor: true,
      });

      rect.on("pointerover", () => {
        rect.setFillStyle(0x808080, 0.3); // Slightly more visible on hover
      });

      rect.on("pointerout", () => {
        rect.setFillStyle(0x808080, 0.1); // Back to very transparent
      });

      rect.on("pointerdown", () => {
        if (onWordClick) {
          onWordClick(word);
        }
        if (word.annotations?.length > 0) {
          console.log("Word bbox position:", {
            x: rect.x + rect.width / 2,
            y: rect.y + rect.height,
          });

          this.showCorrectionMarkMenu(
            rect.x + rect.width / 2,
            rect.y + rect.height
          );
        }
      });
    });
  }

  createAnnotations() {
    const { annotations, correctionMarks, jiix } = this.config;
    if (!annotations || !jiix?.words) return;

    this.mistakeTypesPerLineMap = new Map();

    // Add helper function to get correction mark color
    const getCorrectionMarkColor = (marks, type) => {
      const mark = marks.find((m) => m.abbreviation === type);
      return mark?.color || "#0000ff";
    };

    annotations.forEach((annotation) => {
      // Get color for this annotation's type
      let lineColor = "#0000ff"; // Default blue
      if (annotation.type) {
        // Handle multiple types (separated by |)
        const primaryType = annotation.type.split("|")[0].trim();
        lineColor = getCorrectionMarkColor(correctionMarks, primaryType);
      }

      // Make underline thicker
      const graphics = this.add.graphics();
      graphics.lineStyle(
        8 * SCALE_FACTOR,
        parseInt(lineColor.replace("#", "0x")),
        0.7
      ); // Increased thickness and opacity

      // 2. Position für Korrekturzeichen am rechten Rand berechnen
      let rightmostBoundingBox = -Infinity;
      jiix.words
        .filter((word) => "bounding-box" in word)
        .forEach((word) => {
          const rightmostPoint =
            word["bounding-box"].x + word["bounding-box"].width;
          if (rightmostPoint > rightmostBoundingBox) {
            rightmostBoundingBox = rightmostPoint;
          }
        });

      const spaceBetweenLastWordAndLanguageCorrectionMarks = 100;
      const startingPosLanguageCorrectionMarks =
        rightmostBoundingBox * MM_TO_PIXEL +
        this.offsets.left +
        spaceBetweenLastWordAndLanguageCorrectionMarks;

      // 3. Unterstreichungen und Sammeln der Korrekturzeichen
      let startWordNum = -1;
      let endWordNum = -1;
      let wordLengthSum = 0;

      for (let i = 0; i < jiix.words.length; i++) {
        const word = jiix.words[i];
        wordLengthSum += word.label.length;

        if (
          wordLengthSum >= annotation.startPos &&
          word.label !== " " &&
          word.label !== "\n" &&
          startWordNum === -1
        ) {
          startWordNum = i;
        }
        if (
          wordLengthSum >= annotation.endPos &&
          word.label !== " " &&
          word.label !== "\n"
        ) {
          endWordNum = i;
          break;
        }
      }

      const startWord = jiix.words[startWordNum];
      const endWord = jiix.words[endWordNum];

      if (!startWord || !endWord) return;

      // Unterstreichung und Correction-Text
      const startX =
        this.offsets.left * SCALE_FACTOR +
        startWord["bounding-box"].x * MM_TO_PIXEL * SCALE_FACTOR;
      const startY =
        (startWord["bounding-box"].y * MM_TO_PIXEL + this.offsets.top) *
        SCALE_FACTOR;

      const width =
        (endWord["bounding-box"].x +
          endWord["bounding-box"].width -
          startWord["bounding-box"].x) *
        MM_TO_PIXEL *
        SCALE_FACTOR;

      // Unterstreichung
      graphics.beginPath();
      graphics.moveTo(
        startX,
        startY + startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR
      );
      graphics.lineTo(
        startX + width,
        startY + startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR
      );
      graphics.strokePath();

      // Position correction text higher above the word
      const correctionText = this.add.text(
        startX,
        startY - 40 * SCALE_FACTOR,
        annotation.correct || "✗",
        {
          fontFamily: "Arial",
          fontSize: 40 * SCALE_FACTOR,
          fontWeight: "bold",
          fill: lineColor,
          stroke: "#ffffff",
          strokeThickness: 6,
        }
      );

      if (annotation.type) {
        const currentTypes = annotation.type
          .split("|")
          .map((type) => {
            const mark = correctionMarks.find(
              (mark) => mark.abbreviation === type.trim()
            );
            return mark ? mark.customAbbreviation || mark.abbreviation : type;
          })
          .join("|"); // Join with vertical bar for types of same annotation

        const lineId = startWord.lineId;
        let updatedValue = this.mistakeTypesPerLineMap.get(lineId) || "";
        updatedValue += `${currentTypes},`; // Add comma for different annotations
        this.mistakeTypesPerLineMap.set(lineId, updatedValue);
      }

      // Add click handler for annotation lines
      graphics.setInteractive(
        new Phaser.Geom.Rectangle(
          startX,
          startY,
          width,
          startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR
        ),
        Phaser.Geom.Rectangle.Contains
      );

      graphics.on("pointerdown", (pointer) => {
        this.showCorrectionMarkMenu(
          startX + width / 2, // Center horizontally with the underline
          startY + startWord["bounding-box"].height * MM_TO_PIXEL * SCALE_FACTOR // Position right below the word
        );
      });
    });

    // Draw correction marks at the right margin
    this.mistakeTypesPerLineMap.forEach((letters, lineId) => {
      const line = jiix.lines.find((l) => l.lineId === lineId);
      if (!line?.["bounding-box"]) return;

      const bbox = line["bounding-box"];
      const lineY = bbox.y;
      let xOffset = 0;

      // Remove trailing comma and split by comma to get different annotations
      const annotations = letters.slice(0, -1).split(",");

      annotations.forEach((annotationTypes, index) => {
        // Split by vertical bar to get types of same annotation
        const types = annotationTypes.split("|");

        if (!types[0].trim()) return;

        const x =
          this.startingPosLanguageCorrectionMarks * SCALE_FACTOR + xOffset;
        const y = (lineY * MM_TO_PIXEL + this.offsets.top) * SCALE_FACTOR;

        // Join types with vertical bar for display
        const displayText =
          types.join(" | ") + (index < annotations.length - 1 ? ", " : "");

        // Use color of first type for the whole annotation group
        const color = getCorrectionMarkColor(correctionMarks, types[0].trim());

        const text = this.add.text(x, y, displayText, {
          fontFamily: "Arial",
          fontSize: 30 * SCALE_FACTOR,
          // fontWeight: "bold",
          fill: color,
          stroke: color,
          strokeThickness: 1,
          backgroundColor: "#ffffff",
        });

        xOffset += text.width + 1 * SCALE_FACTOR;
      });
    });
  }

  createContentAnnotations() {
    const contentAnnotations = this.config.annotations
      .filter((annotation) => annotation.category === "content")
      .sort((a, b) => {
        if (a.contentType < b.contentType) return -1;
        if (a.contentType > b.contentType) return 1;
        return a.startLineId - b.startLineId;
      });

    const inhaltlicherAufbauLines = [];
    const umgangMitDemThemaLines = [];

    // Definiere die Abstände als Konstanten
    const baseX = this.offsets.left * SCALE_FACTOR - 50 * SCALE_FACTOR;
    const themaOffset = 80 * SCALE_FACTOR; // Abstand für I-Linien
    const lineSpacing = 40 * SCALE_FACTOR; // Abstand zwischen überlappenden Linien

    contentAnnotations.forEach((annotation) => {
      // Finde die kompletten Linien statt nur der Wörter
      const startLine = this.config.jiix.lines.find(
        (line) => line.lineId === annotation.startLineId
      );
      const endLine = this.config.jiix.lines.find(
        (line) => line.lineId === annotation.endLineId
      );

      if (!startLine?.["bounding-box"] || !endLine?.["bounding-box"]) return;

      const annotationColor = getCorrectionMarkColor(
        this.config.correctionMarks,
        annotation.type
      );

      const graphics = this.add.graphics();

      const x =
        baseX +
        (annotation.contentType === "Umgang mit dem Thema" ? themaOffset : 0);

      // Korrekte Berechnung der Y-Positionen basierend auf den Linien-Bounding-Boxes
      const startY =
        (startLine["bounding-box"].y * MM_TO_PIXEL + this.offsets.top) *
        SCALE_FACTOR;
      const endY =
        ((endLine["bounding-box"].y + endLine["bounding-box"].height) *
          MM_TO_PIXEL +
          this.offsets.top) *
        SCALE_FACTOR;

      if (annotation.contentType === "Inhaltlicher Aufbau") {
        graphics.lineStyle(4 * SCALE_FACTOR, 0x0088ff, 1);
        const dashLength = 5 * SCALE_FACTOR;
        const gapLength = 5 * SCALE_FACTOR;
        let currentY = startY;

        while (currentY < endY) {
          graphics.beginPath();
          graphics.moveTo(x, currentY);
          graphics.lineTo(x, Math.min(currentY + dashLength, endY));
          graphics.strokePath();
          currentY += dashLength + gapLength;
        }
      } else {
        // For I-lines, use the annotation type color
        const annotationColor = getCorrectionMarkColor(
          this.config.correctionMarks,
          annotation.type
        );
        graphics.lineStyle(
          4 * SCALE_FACTOR,
          parseInt(annotationColor.replace("#", "0x")),
          1
        );
        graphics.beginPath();
        graphics.moveTo(x, startY);
        graphics.lineTo(x, endY);
        graphics.strokePath();
      }

      const label =
        annotation.contentType === "Inhaltlicher Aufbau"
          ? `A${inhaltlicherAufbauLines.length + 1}`
          : `I${umgangMitDemThemaLines.length + 1}`;

      // Update the text color based on the annotation type
      const textColor =
        annotation.contentType === "Inhaltlicher Aufbau"
          ? "#08F" // Use #08F for A-labels
          : annotationColor; // Use correction mark color for I-labels

      // Reduziere den vertikalen Abstand zwischen Text und Linie
      const textOffset = 20 * SCALE_FACTOR; // Vorher war es 40 * SCALE_FACTOR

      const text = this.add.text(x, startY - textOffset, label, {
        fontFamily: "Arial",
        fontSize: 35 * SCALE_FACTOR,
        fill: textColor,
        stroke: "#ffffff",
        strokeThickness: 4,
      });

      text.setOrigin(0.5, 1);

      const lineGroup = {
        graphics,
        text,
        startLineId: annotation.startLineId,
        endLineId: annotation.endLineId,
        x: x,
      };

      if (annotation.contentType === "Inhaltlicher Aufbau") {
        inhaltlicherAufbauLines.push(lineGroup);
      } else {
        umgangMitDemThemaLines.push(lineGroup);
      }
    });

    // Anpassen der überlappenden Linien
    this.adjustOverlappingLines(inhaltlicherAufbauLines, lineSpacing);
    this.adjustOverlappingLines(umgangMitDemThemaLines, lineSpacing);
  }

  adjustOverlappingLines(lines, spacing) {
    lines.forEach((currentLine, index) => {
      if (index % 2 > 0) {
        const previousLine = lines[index - 1];
        if (
          parseInt(previousLine.endLineId) <= parseInt(currentLine.startLineId)
        ) {
          // Verschiebe Linie und Label
          currentLine.graphics.x += spacing;
          currentLine.text.x += spacing;
        }
      }
    });
  }

  findWordByLineId(lineId) {
    return this.config.jiix.words.find((word) => word.lineId === lineId);
  }

  // Optional: Methode zum Zurücksetzen des Zooms
  resetZoom() {
    this.currentZoom = 1;
    this.cameras.main.setZoom(1);
  }

  // Optional: Methode zum Setzen eines spezifischen Zooms
  setZoom(value) {
    this.currentZoom = value;
    this.cameras.main.setZoom(value);
  }

  showCorrectionMarkMenu(x, y) {
    if (this.contextMenu) {
      this.contextMenu.destroy(true);
      this.contextMenu = null;
      return;
    }

    this.contextMenu = this.add.container(x, y);
    this.contextMenu.setDepth(1000);

    const commonMarks = ["Sp+", "Sb", "G", "H", "R", "Z", "A"];

    // Calculate total width needed (50 pixels per mark)
    const menuWidth = commonMarks.length * 50 + 30; // Added 30px padding

    // Background for horizontal menu with adjusted width
    const bg = this.add.rectangle(0, 0, menuWidth, 40, 0xffffff);
    bg.setStrokeStyle(2, 0x000000);
    this.contextMenu.add(bg);

    // Adjust starting position based on new width
    let xOffset = -(menuWidth / 2) + 25; // Start from left edge + small padding
    const yPos = 0;

    commonMarks.forEach((baseType) => {
      const mark = this.config.correctionMarks.find(
        (m) =>
          m.abbreviation === baseType && m.annotationCategory === "linguistic"
      );
      if (!mark) return;

      // Add main mark with hover effect
      const text = this.add.text(
        xOffset,
        yPos,
        mark.customAbbreviation || mark.abbreviation,
        {
          fontFamily: "Arial",
          fontSize: 30 * SCALE_FACTOR,
          fill: mark.color,
          backgroundColor: "#ffffff",
          padding: { x: 4, y: 2 }, // Add padding for better highlight appearance
        }
      );
      text.setOrigin(0.5);
      text.setInteractive({ useHandCursor: true });

      // Add hover effects
      text.on("pointerover", () => {
        text.setBackgroundColor("#e0e0e0"); // Light gray on hover
      });

      text.on("pointerout", () => {
        text.setBackgroundColor("#ffffff"); // Back to white
      });

      this.contextMenu.add(text);

      // Add expand button if there are variants
      const variants = this.config.correctionMarks.filter(
        (m) =>
          m.abbreviation.startsWith(baseType + "(") &&
          m.annotationCategory === "linguistic"
      );

      if (variants.length > 0) {
        // Make expand button bigger and add hover effects
        const expandButton = this.add.text(xOffset, yPos + 25, "▼", {
          fontFamily: "Arial",
          fontSize: 35 * SCALE_FACTOR, // Increased from 25
          fill: "#000000",
          padding: { x: 8, y: 4 }, // Added padding for larger hit area
        });
        expandButton.setOrigin(0.5);
        expandButton.setInteractive({
          useHandCursor: true,
          hitArea: new Phaser.Geom.Rectangle(-20, -20, 40, 40), // Increased hit area
          hitAreaCallback: Phaser.Geom.Rectangle.Contains,
        });

        // Add hover effects
        expandButton.on("pointerover", () => {
          expandButton.setFill("#ff0000"); // Red on hover
        });

        expandButton.on("pointerout", () => {
          expandButton.setFill("#000000"); // Back to black
        });

        expandButton.on("pointerdown", () => {
          // Position variants below the expand button
          const buttonBottom = yPos + 40; // Adjusted to account for new button position

          // Create background for variants
          const variantBg = this.add.rectangle(
            xOffset,
            buttonBottom + 15,
            80,
            variants.length * 35,
            0xffffff
          );
          variantBg.setStrokeStyle(2, 0x000000);
          variantBg.setOrigin(0.5, 0);
          this.contextMenu.add(variantBg);

          // Add variants
          variants.forEach((variant, index) => {
            const variantText = this.add.text(
              xOffset,
              buttonBottom + 35 + index * 35,
              variant.customAbbreviation || variant.abbreviation,
              {
                fontFamily: "Arial",
                fontSize: 30 * SCALE_FACTOR,
                fill: variant.color,
                backgroundColor: "#ffffff",
                padding: { x: 4, y: 2 },
              }
            );
            variantText.setOrigin(0.5);
            variantText.setInteractive({ useHandCursor: true });

            // Add hover effects
            variantText.on("pointerover", () => {
              variantText.setBackgroundColor("#e0e0e0");
            });

            variantText.on("pointerout", () => {
              variantText.setBackgroundColor("#ffffff");
            });

            this.contextMenu.add(variantText);
          });

          // Change expand button to collapse and keep hover effects
          expandButton.setText("▲");
          expandButton.removeInteractive();
          expandButton.setFill("#000000"); // Reset color when disabled
        });

        this.contextMenu.add(expandButton);
      }

      xOffset += 50;
    });
  }
}

const HandwritingCanvasPhaser = (props) => {
  const gameRef = useRef(null);
  const containerRef = useRef(null);
  const isDraggingRef = useRef(false);
  const lastPositionRef = useRef({ x: 0, y: 0 });

  useEffect(() => {
    if (gameRef.current) {
      gameRef.current.destroy(true);
      gameRef.current = null;
    }

    const config = {
      type: Phaser.AUTO,
      parent: containerRef.current,
      backgroundColor: "#ffffff",
      scale: {
        mode: Phaser.Scale.NONE,
        width: window.innerWidth - 50,
        height: window.innerHeight * 3, // Start with a large height
        autoRound: false,
      },
      scene: new HandwritingScene(props),
    };

    gameRef.current = new Phaser.Game(config);

    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
        gameRef.current = null;
      }
    };
  }, [props.handwritingImageUrl, props.annotations]);

  const handleMouseDown = (e) => {
    // Nur aktivieren wenn es kein Stift ist
    if (e.pointerType !== "pen") {
      isDraggingRef.current = true;
      lastPositionRef.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
  };

  const handleMouseMove = (e) => {
    if (isDraggingRef.current && e.pointerType !== "pen") {
      const deltaY = lastPositionRef.current.y - e.clientY;
      const container = containerRef.current;
      if (container) {
        container.scrollTop += deltaY;
      }
      lastPositionRef.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
  };

  const handleMouseUp = () => {
    isDraggingRef.current = false;
  };

  const handleWheel = (e) => {
    // Verhindere Zoom bei Strg+Mausrad
    if (e.ctrlKey) {
      e.preventDefault();
      return;
    }

    const container = containerRef.current;
    if (container) {
      // Sanfteres Scrollen
      container.scrollTop += e.deltaY * 0.5;
    }
  };

  // Zoom-Funktionen
  const handleZoom = (factor) => {
    const scene = gameRef.current?.scene.scenes[0];
    if (scene) {
      scene.currentZoom *= factor;
      scene.cameras.main.setZoom(scene.currentZoom);
    }
  };

  const handleReset = () => {
    const scene = gameRef.current?.scene.scenes[0];
    if (scene) {
      scene.currentZoom = SCALE_FACTOR;
      scene.cameras.main.setZoom(SCALE_FACTOR);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 100px)",
          overflowY: "auto",
          overflowX: "hidden",
          position: "relative",
          WebkitOverflowScrolling: "touch",
          touchAction: "pan-y",
        }}
        ref={containerRef}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          display: "flex",
          gap: "10px",
        }}
      >
        <button onClick={() => handleZoom(1.1)}>+</button>
        <button onClick={() => handleZoom(0.9)}>-</button>
        <button onClick={handleReset}>Reset</button>
      </div>
    </div>
  );
};

export default HandwritingCanvasPhaser;

// Helper function to get correction mark color
function getCorrectionMarkColor(correctionMarks, type) {
  const mark = correctionMarks.find(
    (mark) => mark.type === type || mark.abbreviation === type
  );
  return mark?.color || "#0000ff"; // default blue if not found
}
