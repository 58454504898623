import { useEffect, useRef } from "react";
import { Application, Assets, Sprite, Graphics, Text } from "pixi.js";

const PixiPage = () => {
  const pixiContainer = useRef(null);

  useEffect(() => {
    const initPixi = async () => {
      // Create a PixiJS application
      const app = new Application();

      // Initialize the application
      await app.init({ background: "#1099bb", resizeTo: window });

      // Add canvas to our container
      pixiContainer.current.appendChild(app.canvas);

      // Load the bunny texture
      const texture = await Assets.load("https://pixijs.com/assets/bunny.png");

      // Create bunny sprite
      const bunny = new Sprite(texture);

      // Add to stage
      app.stage.addChild(bunny);

      // Center the sprite's anchor point
      bunny.anchor.set(0.5);

      // Move the sprite to the center of the screen
      bunny.x = app.screen.width / 2;
      bunny.y = app.screen.height / 2;

      // Rechteck erstellen
      const rectangle = new Graphics();
      rectangle
        .beginFill(0xff0000) // Rote Farbe
        .drawRect(100, 100, 150, 100)
        .endFill();

      // Text über dem Rechteck
      const text = new Text("Rechteck", {
        fontFamily: "Arial",
        fontSize: 24,
        fill: 0x000000,
      });
      text.position.set(100, 70); // Position über dem Rechteck

      // Vertikaler blauer Strich
      const line = new Graphics();
      line
        .lineStyle(3, 0x0000ff) // 3 Pixel dick, blau
        .moveTo(270, 100) // Startpunkt (rechts neben Rechteck)
        .lineTo(270, 200); // Endpunkt (gleiche Höhe wie Rechteck)

      // "R" Text
      const rText = new Text("R", {
        fontFamily: "Arial",
        fontSize: 24,
        fill: 0x0000ff,
      });
      rText.position.set(290, 140); // Position rechts neben Strich

      // Interaktiv machen
      rectangle.eventMode = "static";
      rectangle.cursor = "pointer";

      // Hover-Effekt
      rectangle.on("pointerover", () => {
        rectangle.clear();
        rectangle
          .beginFill(0x00ff00) // Grüne Farbe beim Hover
          .drawRect(100, 100, 150, 100)
          .endFill();
      });

      // Hover Ende
      rectangle.on("pointerout", () => {
        rectangle.clear();
        rectangle
          .beginFill(0xff0000) // Zurück zu Rot
          .drawRect(100, 100, 150, 100)
          .endFill();
      });

      // Click-Handler
      rectangle.on("pointerdown", () => {
        rectangle.clear();
        rectangle
          .beginFill(0x0000ff) // Blaue Farbe beim Klick
          .drawRect(100, 100, 150, 100)
          .endFill();
      });

      // Alles zur Stage hinzufügen
      app.stage.addChild(rectangle);
      app.stage.addChild(text);
      app.stage.addChild(line);
      app.stage.addChild(rText);

      // Cleanup
      return () => {
        app.destroy(true);
      };
    };

    initPixi();
  }, []);

  return <div ref={pixiContainer}></div>;
};

export default PixiPage;
