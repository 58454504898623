import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import db from "../utils/db";
import { doc, deleteDoc } from "firebase/firestore";
import storage from "../utils/storage";

import { useDocumentData } from "react-firebase-hooks/firestore";

import { ref, deleteObject, listAll } from "firebase/storage";

import { Button, Space, message } from "antd";

import { useAuth } from "../App";

import "./DeleteExamPage.css";

const DeleteExamPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  let { user } = useAuth();

  const { examIdProp } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [examData, examDataLoading, examDataError] = useDocumentData(
    doc(db, `users/${user.uid}/exams`, examIdProp),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const deleteExam = async (examId) => {
    if (examData !== "CQQ4YNsox6L7JMjmppUK") {
      try {
        const examRef = doc(db, `users/${user.uid}/exams`, examId);
        await deleteDoc(examRef); // Delete the document from Firestore

        const storageRef = ref(storage, `users/${user.uid}/exams/${examId}`);
        // List all files under the submission folder
        listAll(storageRef)
          .then((res) => {
            // Delete all files under the submission folder except all_pages.jpg
            res.items.forEach((item) => {
              // Check if the file is not all_pages.jpg
              deleteObject(item).catch((error) => {
                console.error("Error deleting file: ", error);
              });
            });
          })
          .catch((error) => {
            console.error("Error listing files: ", error);
          });
      } catch (error) {
        if (error.code === "storage/object-not-found") {
          console.log(
            `The object at path 'users/${user.uid}/exams/${examId}' does not exist or was already deleted.`
          );
        } else {
          // Handle other errors
          console.error(
            "An error occurred during the deletion process:",
            error
          );
        }
      } finally {
        navigate("/view", { replace: true });
      }
    } else {
      message.error(t('deleteExam.testExamError'));
    }
  };

  const handleCancelClick = () => {
    navigate(`/view`, { replace: true });
  };

  return (
    <div className="container">
      {examData && (
        <React.Fragment>
          <b dangerouslySetInnerHTML={{
            __html: t('deleteExam.confirmDelete', { name: examData.name })
          }} />
          <br />
          <br />
          <Space>
            <Button onClick={handleCancelClick} type="primary" shape="round">
              {t('deleteExam.cancel')}
            </Button>
            <Button
              danger
              onClick={() => deleteExam(examIdProp)}
              type="primary"
              shape="round"
            >
              {t('deleteExam.delete')}
            </Button>
          </Space>
        </React.Fragment>
      )}
    </div>
  );
};

export default DeleteExamPage;
