import { useState } from "react";
import { message } from "antd";
import { collection, addDoc, onSnapshot } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import db from "../utils/db";

export const useSubscription = (user) => {
  const { t } = useTranslation();
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [isSubscriptionModalVisible, setIsSubscriptionModalVisible] = useState(false);

  const showSubscriptionModal = () => {
    setIsSubscriptionModalVisible(true);
  };

  const handleSubscriptionModalCancel = () => {
    setIsSubscriptionModalVisible(false);
  };

  const purchaseSubscription = async (plan) => {
    message.loading(t('subscription.messages.redirecting'));
    setLoadingStripe(true);

    try {
      const checkoutData =
        plan === "Pay as you go"
          ? {
              mode: "subscription",
              line_items: [
                {
                  price: "price_1QLqTRIBcEHZy5P9tiVB24Hq",
                },
              ],
              success_url: window.location.origin + "?setup_success=true",
              cancel_url: window.location.origin,
              client_reference_id: user.uid,
              customer_email: user.email,
              automatic_tax: { enabled: true },
              allow_promotion_codes: true,
              consent_collection: {
                terms_of_service: "required",
              },
              locale: t('subscription.checkout.locale'),
              invoice_creation: {
                enabled: true,
              },
              metadata: {
                customer_id: user.uid,
                plan: "pay_as_you_go",
              },
            }
          : {
              mode: "subscription",
              line_items: [
                {
                  price:
                    plan === "Silber"
                      ? "price_1PlTbiIBcEHZy5P9GqesgWVy"
                      : "price_1PlTbdIBcEHZy5P9rUiZxVA0",
                  quantity: 1,
                },
              ],
              success_url: window.location.origin + "?payment_success=true",
              cancel_url: window.location.origin,
              client_reference_id: user.uid,
              automatic_tax: { enabled: true },
              allow_promotion_codes: true,
              consent_collection: {
                terms_of_service: "required",
              },
              locale: t('subscription.checkout.locale'),
              invoice_creation: {
                enabled: true,
              },
            };

      const docRef = await addDoc(
        collection(db, "customers", user.uid, "checkout_sessions"),
        checkoutData
      );

      onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          console.error(`An error occurred: ${error.message}`);
          setLoadingStripe(false);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    } catch (error) {
      console.error("Error creating checkout session:", error);
      message.error(t('subscription.messages.error'));
    } finally {
      setLoadingStripe(false);
    }
  };

  const handleSubscribe = (plan) => {
    setIsSubscriptionModalVisible(false);
    purchaseSubscription(plan);
  };

  return {
    loadingStripe,
    isSubscriptionModalVisible,
    showSubscriptionModal,
    handleSubscriptionModalCancel,
    handleSubscribe,
  };
};
