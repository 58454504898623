import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import firebaseApp from "../utils/config";

const db = getFirestore(firebaseApp);
// enableIndexedDbPersistence(db)
//   .then(() => console.log("Enabled offline persistence"))
//   .catch((err) => {
//     if (err.code === "failed-precondition") {
//       console.log(
//         "Multiple tabs open, persistence can only be enabled in one tab at a a time."
//       );
//     } else if (err.code === "unimplemented") {
//       console.log(
//         "The current browser does not support all of the features required to enable persistence."
//       );
//     }
//   });
// Subsequent queries will use persistence, if it was enabled successfully

export default db;
