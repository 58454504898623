import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./App";
import "./App.css";
// import { ClerkProvider } from "@clerk/clerk-react";
// import { deDE } from "@clerk/localizations";

// const rootElement = document.getElementById("root");
// const root = createRoot(rootElement);

// root.render(<App />);

import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import './i18n';

const options = {
  api_host: "https://eu.i.posthog.com",
};

Sentry.init({
  dsn: "https://1f6f87dd5ecb5896a13dd06cde3bc035@o4507451953250304.ingest.de.sentry.io/4507451955019856",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app\.zengrade\.com\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// const publishableKey =
//   window.location.hostname === "localhost"
//     ? "pk_test_c21vb3RoLW1lZXJrYXQtNTkuY2xlcmsuYWNjb3VudHMuZGV2JA"
//     : "pk_live_Y2xlcmsuemVuZ3JhZGUuY29tJA";

root.render(
  <PostHogProvider
    apiKey={"phc_IzwSIclV0F3oxmEku8RYkrihnaj2WCRrWYwGFrDKnSm"}
    options={options}
  >
    <App />
  </PostHogProvider>
);

// Add this for unhandled promise rejections
window.addEventListener("unhandledrejection", (event) => {
  console.error("Unhandled promise rejection:", event.reason);
});
