import React from "react";
import { Card, Typography, Button, Space } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;

const SubscriptionContent = ({ onSubscribe }) => {
  const { t } = useTranslation();

  const features = [
    "startImmediately",
    "noBasicFee",
    "noMinimumUsage",
    "fullFlexibility",
    "payWhatYouUse",
    "billingPeriod",
    "fullConfiguration",
  ];

  return (
    <>
      <Card
        title={
          <Title level={3}>{t("layout.subscription.payAsYouGo.title")}</Title>
        }
        style={{ textAlign: "center" }}
      >
        <Text>{t("layout.subscription.payAsYouGo.pricePerPage")}</Text>
        <Title>
          <Text style={{ fontSize: "16px" }}>
            {t("layout.subscription.payAsYouGo.subtitle")}
          </Text>
        </Title>
        <Text>
          <ul style={{ textAlign: "left", paddingLeft: "20px" }}>
            {features.map((feature) => (
              <li key={feature}>
                {t(`layout.subscription.payAsYouGo.features.${feature}`)}
              </li>
            ))}
          </ul>
        </Text>
        <br />
        <br />
        <Button
          type="primary"
          size="large"
          block
          onClick={() => onSubscribe("Pay as you go")}
        >
          {t("layout.subscription.payAsYouGo.continue")}
        </Button>
        <Space style={{ marginTop: 16 }}>
          <a
            href="https://zengrade.notion.site/Nutzungsbedingungen-1d0dd627d65e423e90b14fb27a3ea75a"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("subscription.links.termsOfUse")}
          </a>
          <a
            href="https://zengrade.notion.site/Datenschutzerkl-rung-3ab3c80c135c458494e8b6f7f1d6b727"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("subscription.links.privacyPolicy")}
          </a>
          <a
            href="https://zengrade.notion.site/Auftragsverarbeitungsvertrag-a5c85050bd104bed8a0469db826b20b5?pvs=74"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("subscription.links.dataProcessingAgreement")}
          </a>
        </Space>
      </Card>
    </>
  );
};

export default SubscriptionContent;
