import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import auth from "../utils/auth";
import { useAuth } from "../App";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { message, Space } from "antd";
import ZengradeLogo from "../assets/zengrade-logo-nobackground.svg";

const LoginPage = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { user } = useAuth();
  let location = useLocation();
  const firebaseuiElement = useRef(null);

  const signOut = () => {
    auth
      .signOut()
      .then(function () {
        navigate("/login", { replace: true });
      })
      .catch(function () {
        navigate("/login", { replace: true });
      });
  };

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        navigate("/view", { replace: true });
      },
      signInFailure: (err) => {
        console.error(t('login.signInError', { message: err.message }));
      },
    },
  };

  useEffect(() => {
    const updateUIText = () => {
      const emailSignInLabel = document.querySelector(".firebaseui-title");
      if (emailSignInLabel) {
        if (emailSignInLabel.textContent === "Sign in with email") {
          emailSignInLabel.textContent = t('login.emailSignIn');
        } else if (emailSignInLabel.textContent === "Sign-in email sent") {
          emailSignInLabel.textContent = t('login.signInEmailSent');
        }
      }

      const signInEmailSent = document.querySelector(".firebaseui-text");
      if (
        signInEmailSent &&
        signInEmailSent.textContent.includes(
          "A sign-in email with additional instructions was sent to"
        )
      ) {
        const emailElement = signInEmailSent.querySelector("strong");
        if (emailElement) {
          const email = emailElement.textContent;
          signInEmailSent.innerHTML = t('login.emailSentMessage', { email });
        }
      }

      const emailLabel = document.querySelector(
        'label[for="ui-sign-in-email-input"]'
      );
      if (emailLabel && emailLabel.textContent === "Email") {
        emailLabel.textContent = t('login.emailLabel');
      }

      const emailError = document.querySelector(".firebaseui-id-email-error");
      if (
        emailError &&
        emailError.textContent === "Enter your email address to continue"
      ) {
        emailError.textContent = t('login.enterEmail');
      }

      const submitButton = document.querySelector(".firebaseui-id-submit");
      if (submitButton && submitButton.textContent === "Next") {
        submitButton.textContent = t('login.next');
      }

      const backButton = document.querySelector(
        ".firebaseui-id-secondary-link"
      );
      if (backButton && backButton.textContent === "Back") {
        backButton.textContent = t('login.back');
      }

      const troubleLink = document.querySelector(
        ".firebaseui-id-trouble-getting-email-link"
      );
      if (troubleLink && troubleLink.textContent === "Trouble getting email?") {
        troubleLink.textContent = t('login.troubleEmail');
      }

      const troubleGettingEmailPage = document.querySelector(
        ".firebaseui-id-page-email-not-received"
      );
      if (troubleGettingEmailPage) {
        const title =
          troubleGettingEmailPage.querySelector(".firebaseui-title");
        if (title && title.textContent === "Trouble getting email?") {
          title.textContent = t('login.troubleEmailTitle');
        }

        const paragraphs =
          troubleGettingEmailPage.querySelectorAll(".firebaseui-text");
        paragraphs.forEach((p) => {
          if (p.textContent === "Try these common fixes:") {
            p.textContent = t('login.commonFixes');
          } else if (p.textContent.includes("If the steps above didn't work")) {
            p.textContent = t('login.stepsNotWorking');
          }
        });

        const listItems = troubleGettingEmailPage.querySelectorAll("ul li");
        const translatedListItems = [
          t('login.checkSpam'),
          t('login.checkInternet'),
          t('login.checkEmailSpelling'),
          t('login.checkInbox')
        ];
        listItems.forEach((li, index) => {
          if (index < translatedListItems.length) {
            li.textContent = translatedListItems[index];
          }
        });

        const resendLink = troubleGettingEmailPage.querySelector(
          ".firebaseui-id-resend-email-link"
        );
        if (resendLink && resendLink.textContent === "Resend") {
          resendLink.textContent = t('login.resend');
        }
      }
    };

    setTimeout(updateUIText, 100);

    const observeDOM = () => {
      const targetNode = document.body;
      const config = { childList: true, subtree: true };

      const callback = function (mutationsList, observer) {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            updateUIText();
          }
        }
      };

      const observer = new MutationObserver(callback);
      observer.observe(targetNode, config);

      return observer;
    };

    const observer = observeDOM();
    updateUIText();

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [t]);

  useEffect(() => {
    if (user) {
      const redirectTo = location.state?.from?.pathname || "/view";
      navigate(redirectTo, { replace: true });
      return;
    }

    const loadFirebaseUI = () => {
      window.firebase = firebase;
      let ui =
        firebaseui.auth.AuthUI.getInstance() ||
        new firebaseui.auth.AuthUI(auth);
      ui.start(firebaseuiElement.current, uiConfig);
    };

    loadFirebaseUI();
  }, [user, navigate, location]);

  return (
    <Space
      direction="vertical"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <img
        src={ZengradeLogo}
        alt={t('login.logo')}
        style={{ width: "200px", height: "200px" }}
      />
      {!user && (
        <div style={{ width: "400px" }}>
          <div ref={firebaseuiElement}></div>
        </div>
      )}
      {user && (
        <React.Fragment>
          <p style={{ textAlign: "center" }}>
            {t('login.welcome', { name: user.displayName })} <br />
            <small>{user.email}</small> <br />
            <button onClick={signOut}>{t('login.signOut')}</button>
          </p>
        </React.Fragment>
      )}
    </Space>
  );
};

export default LoginPage;
