import React from "react";
import { Modal } from "antd";
import SubscriptionContent from "./SubscriptionContent";

const SubscriptionModal = ({ isOpen, onCancel, onSubscribe }) => {
  return (
    <Modal
      // title="Wählen Sie Ihr Abonnement"
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      width={800}
    >
      <SubscriptionContent onSubscribe={onSubscribe} />
    </Modal>
  );
};

export default SubscriptionModal;
